/* eslint-disable jsx-a11y/anchor-is-valid */
import React ,{useMemo,useState} from "react";
import cs from "../css/widgets.module.css";
import { FaHandHolding } from "react-icons/fa";
import { SwalError,SwalWarn } from "../../Popup";
import axios from "axios"
import Cookies from "js-cookie"
import { NODE_API_URL,BASE_URL } from "../../../../env_config";
export const TotalOrderPlaceTileChurned = (props) => {

  const userData = useMemo(()=>localStorage.getItem("userData"),[])
  const user = JSON.parse(userData)
  const [openerrorpopup,setErrorPopup] = useState(false)
  const [kycmessage,setKycMessage] = useState("")
  const GetKycRejected = () => {
    setErrorPopup(true)
    if(props.userinfo.kyc_status === "KYC_REJ"){
      axios({
        method: "GET",
        url: NODE_API_URL + "/AUS/getKycPendingData",
        headers: { 
          "Content-Type": "application/json" ,
          "Authorization": `Bearer ${Cookies.get("cust_auth_token")}`
        }
      }).then((res) => {
        if (res && res.data && res.data.success) {
          setKycMessage("Your KYC is Rejected." + " Reason is " + res.data.data.reject_comment_cus)
        }
      }).
      catch(error => {
        if(error?.response?.data?.message === "UnAuthorized!"){
          var cookies = document.cookie.split(";");
        for (var i = 0; i < cookies.length; i++) {
          if(cookies[i] && !cookies[i].includes("view")){
            var equals = cookies[i].indexOf("=");
            var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
          }
        }
        
        window.location.replace(`${BASE_URL}/logout`);
      }
      })
    }
  }
  return (
    <>
    <SwalWarn show={openerrorpopup} onClose={() => setErrorPopup(false)} message={props.userinfo.kyc_status === "KYC_PEND"?"Your KYC verification in progress. We’ll notify you once approved. ":props.userinfo.kyc_status === "NO_KYC"?"KYC submission.":props.userinfo.kyc_status === "KYC_REJ"?kycmessage:""}/>
      <div className={`card card-custom gutter-b ${cs.OrderPlaceTiles} ${cs.dashTileHeight}`}  >
      <a href="/confirmgoods">
            <div className="small-box cart-info-box">
                <div className="inner">
                    <p className="my-2 mt-5 font-size-h6 font-weight-bold">Pending Delivery</p>
                    <p className="display-3 mt-5 font-weight-bold">{props.totalOrderCount ? props.totalOrderCount : "0"}</p>
                </div>
                <div className="icon">
                    <img alt="" src="/media/General/cart_dashboard.svg" />
                </div>
            </div>
          </a>
       
        {/* <a  href="#" >
            <div className="card-body">
              <div className="row align-items-end">
                <div className="col-8">
                  <div className={cs.bgIcon}>
                      <FaHandHolding className={cs.handIcon} />
                  </div>
                <p className="text-light font-weight-bold font-size-lg mt-2 mb-0">{props.language.DASHBOARD_TOTAL_ORDERS}</p>
                </div>
                <div className="col-4 text-right">

                  <div className="text-white font-weight-bold display-3 mt-1"> {props.totalOrderCount ? props.totalOrderCount : "0"} </div>
                </div>
              </div>
            </div>
          </a> */}
      </div>
    </>
  );
}
