import React from "react";
import { toAbsoluteUrl } from "../../../_helpers";
import { FaInfoCircle } from 'react-icons/fa';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import CertificateLink from "../../CertificateLink";

function numberWithCommas(x) {
    return x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
export const InvoiceExpandColumns = (row,language)  => [
    {
        Header:language.PROFORMA_DETAIL_DIAMONDS_SR,
        accessor: "",
        className:"text-center align-middle mr-3",
        Cell: (row) => {
            return <div>{(parseInt(row.row.id) + 1)}</div>;
        },
        disableSortBy: true,
        disableFilters: true,
    },
    {
        Header: language.DETAIL_DIAMONDDETAILS,
        accessor: (d) => (
            <>
            {d.diamond_type === "PN" || d.diamond_type === "PL" ? 
                <div className="row">
                    <div className="col-2 text-center p-0">
                        <img
                            className="img-fluid mr-2"
                            alt={d.shape}
                            src={toAbsoluteUrl(`/media/shape/svg_shape/parcel/${d.shape.toLowerCase()}.svg`)}
                            width="50px"
                            onError={e => {
                                e.currentTarget.src = toAbsoluteUrl(
                                    `/media/shape/svg_shape/parcel/other.svg`
                                    )
                                }}
                        />
                    </div>
                    <div className="col-10 pl-2 p-0 d-flex align-items-end text-left">
                        <div>
                            <div className={`mb-1 ${d.diamond_type === "PN" ? "natTab" : "labTab"}`}>
                                <span className={`${d.diamond_type}_diamond mr-3 small`}>
                                    {d.diamond_type}
                                </span>
                                <span className={`cursor-pointer small ml-2`}>
                                    {d.diamond_type}-{d.stockId}
                                </span>
                                <span className={`text-capitalize small ml-2`}>
                                    By: {d.type}
                                </span>
                                <span className={`text-capitalize small ml-2`}>
                                    {d.length_mm?d.length_mm.toFixed(2):"" }-{d.width_mm?d.width_mm.toFixed(2):"" } MM
                                </span>
                            </div>
    
                            <span className="text-dark font-weight-bold d-block">
                                {d.shape}{" "}
                                {d.weight?d.weight.toFixed(2):""}Ct.{" "}
                                {d.pieces}Pcs.{" "}
                                {d.color}{" "}
                                {d.clarity}{" "}
                            </span>
                        </div>
                        {d.clientRef ? 
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id="tooltip">
                                        {d.clientRef||""}
                                    </Tooltip>
                                }
                            >
                                <FaInfoCircle className="font-size-h6 ml-2 mb-1 text-dark" />
                            </OverlayTrigger>
                        :null}
                    </div>
                </div>
             :
                <div className="row">
                    <div className="col-2 text-center p-0">
                        {d.image ? (
                            <img
                                className="img-fluid mr-2"
                                alt={d.shape}
                                src={d.image}
                                width="50px"
                                onError={e => {
                                    e.currentTarget.src = toAbsoluteUrl(
                                        `/media/shape/${d.shape ? d.shape.toLowerCase() : ""}-no.png`
                                        )
                                    }}
                            />
                        ) : (
                            <img
                                className="img-fluid mr-2"
                                alt={d.shape}
                                src={toAbsoluteUrl(
                                    `/media/shape/${d.shape ? d.shape.toLowerCase() : ""}-no.png`
                                )}
                                onError={e => {
                                    e.currentTarget.src = toAbsoluteUrl(
                                        `/media/shape/noimage.jpg`
                                    )
                                }}
                                width="50px"
                                style={{ backgroundColor: "#f4f4f4" }}
                            />
                        )}
                    </div>
                    <div className="col-10 d-flex align-items-end pl-2 p-0 text-left">
                        <div>
                            <div className={`mb-1 ${d.diamond_type === "N" ? "natTab" : "labTab"}`}>
                                <span className={`${ d.diamond_type === "N" ? d.isfancy ? "F" : "W" : d.isfancy ? "LF" : "L"  }_diamond mr-3 small`}>
                                    {d.diamond_type === "N" || d.diamond_type === "W" ? d.isfancy  ? "NF" : "N" : d.isfancy ? "LF" : "L"}
                                </span>
                                
                                <CertificateLink Certificate_link={d.certificate_link} lab={d.lab} certificate={d.certificate}  className={`mx-2 small`} />
                                <span  className={`cursor-pointer small ml-2`}>
                                    {d.diamond_type === "N" ? "N" : "L"}-{d.stockId}
                                </span>
                            </div>
    
                            <span className="text-dark font-weight-bold d-block">
                                {d.shape}{" "}
                                {d.weight}{" "}
                                {d.color}{" "}
                                {d.clarity}{" "}{d.cut}{" "}{d.polish}{" "}{d.symmetry}{" "}
                                {d.fluorescence}
                            </span>
                        </div>
                        {d.clientRef ? 
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id="tooltip">
                                        {d.clientRef||""}
                                    </Tooltip>
                                }
                            >
                                <FaInfoCircle className="font-size-h6 ml-2 mb-1 text-dark" />
                            </OverlayTrigger>
                        :null}
                    </div>
                </div> 
                }
            </>
        ),
        className: "text-center align-middle",
        disableSortBy: true,
    },
   
    {
        Header:language.CONFIRM_GOODS_POPUP_DIAMOND_LOCATION,
        className:"text-center align-middle mr-3",
        accessor: "country",
    },
    {
        Header:language.PROFORMA_DETAIL_DIAMONDS_HSCODE,
        className:"text-center align-middle mr-3",
        accessor: (d) => (
            <> 
                {d.diamond_type === "N" || d.diamond_type === "W" ? "71023910" : d.diamond_type === "L"?"71049120":d.diamond_type === "PL"?"71049190":d.cg_carat > 0.99?"71023920" : "71023910"}
            </>
        ),
    },
   
    {
        Header:language.SEARCHLIST_DISC,
        className:"text-center align-middle mr-3",
        // accessor: d => Number(d.discount),
        sortMethod: (a, b) => Number(a)-Number(b),
        // Cell: (row) => {
        //     return <span className={`${(row.value >= 0) ? 'text-success' : 'text-danger'}`}>{row.value}%</span>;
        //   }
        accessor:(d) => {
            return <>{d.diamond_type === "N" || d.diamond_type === "W" || d.diamond_type === "L"?<span className={`${(row.value >= 0) ? 'text-success' : 'text-danger'}`}>{d.discount}%</span>:"-"}</>;
        }
    },
    {
        Header:'$/CT',
        className:"text-center align-middle mr-3",
        accessor: d => Number(d["ourRateExchange"]),
        sortMethod: (a, b) => Number(a)-Number(b),
        Cell: (row) => {
            return <span className="font-weight-bold">
                    ${numberWithCommas(parseFloat(row.value))}
                </span>
          }
    },
    {
        Header:"Total AUD",
        className:"text-center align-middle mr-3",
        accessor: d => Number(d["ourPriceExchange"]),
        sortMethod: (a, b) => Number(a)-Number(b),
        Cell: (row) => {
            return <span className="font-weight-bold">
                    ${numberWithCommas(parseFloat(row.value))}
                </span>
          }
    },
];
