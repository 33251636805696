/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import cs from "../css/widgets.module.css";
import {toAbsoluteUrl} from "../../../_helpers";

export const IncompleteProfileTile = () => {
  return (
    <>
      <div className={`card card-custom gutter-b ${cs.incompleteProfile} ${cs.dashTileHeight}`}  >
          <div className="card-body py-5">
            <div className="row">
                <div className="col-8">
                    <h3 className="font-weight-bolder text-dark">KYC submission.</h3>
                    <p>
                        Kindly submit your KYC documents to place your orders.  
                    </p>
                    <a href="/profile" className="btn btn-danger font-weight-bold btn-square">Complete Profile</a>
                </div>
                <div className="col-4 d-flex align-items-center justify-content-center">
                    <img className="img-fluid" height="100px" alt="profile" src={toAbsoluteUrl(`/media/General/incomplete-dashboard.png`)}/>
                </div>
            </div>
          </div>
      </div>
    </>
  );
}
