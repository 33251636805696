import React, { useState,useEffect } from 'react';
import {Snackbar,IconButton,makeStyles,SnackbarContent} from '@material-ui/core';
import {OverlayTrigger,Tooltip} from "react-bootstrap";
import { toAbsoluteUrl } from '../../_helpers';
import { FaInfoCircle,FaLongArrowAltUp,FaLongArrowAltDown} from "react-icons/fa";
import { BsLightningFill } from "react-icons/bs";
// import {GiCutDiamond} from "react-icons/gi"
import SVG from "react-inlinesvg";
import {Modal,Button} from "react-bootstrap";
import axios from 'axios';
import VideoIframe from '../detailPage/VideoIframe';
import ErrorIcon from "@material-ui/icons/Error";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";
import green from "material-ui/colors/green";
import red from "material-ui/colors/red";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
// import { MainImagePopup } from '../widgets/tiles/MainImagePopUp';
import { BASE_URL, IVPCURRENCY, NODE_API_URL } from '../../../env_config';
import Cookies from "js-cookie";
// import {ConfirmGoods,HoldPopup} from '../Popup'
import { SwalWarn } from '../Popup';
import { ImageExpandPopupCommon } from '../widgets/tiles/ImageExpandPopupCommon';
import { VideoExpandPopupCommon } from '../widgets/tiles/VideoExpandPopupCommon';
import { MainImageExpandedDetailsPopup } from '../widgets/tiles/MainImageExpandedDetailsPopup';
import CertificateLink from '../CertificateLink';

export default function ExpandedDetails({row,open,conversionrate,symbol,language,BuyDiamond,HoldDiamond,symbolname,userinfo}){
    const userData = React.useMemo(() => localStorage.getItem("userData"), []);
    const user = JSON.parse(userData);
    const [showVideo, setShowVideo] = React.useState(false);
    const [showAssetImg, setShowAssetImg] = React.useState(false);
    const [showArrowImg, setShowArrowImg] = React.useState(false);
    const [showHeartImg, setShowHeartImg] = React.useState(false);
    const [swalWarnOpen,setSwalWarnOpen] = useState(false)
    const [alertMessage,setAlertMessage] = useState()
    const [Toast] = useState(false)
    const [wishlistmessage] = useState(null)
    const [currentCurrRate,setCurrentCurrRate] = useState("1")
    const GenerateTitle = (row) => {
        let title = ""
        // if(row.lab){
        //     title += row.lab
        // }
        if(row.shape){
            title += " "
            title += row.shape
        }
        if(row.carat){
            title += " "
            title += row.carat.toFixed(2) 
        }
        // if(row.fancy_intensity){
        //     title += " "
        //     title += row.fancy_intensity
        // }
        if(row.color){
            title += " "
            title += row.color
        }
        if(row.clarity){
            title += " "
            title += row.clarity
        }

        if(row.cut){
            title += " "
            title += row.cut
        }
       
        if(row.polish){
            title += " "
            title += row.polish
        }

        if(row.symmetry){
            title += " "
            title += row.symmetry
        }

        if(row.fluorescence){
            title += " "
            title += row.fluorescence
        }
        return title
    }
    // const RouteToDetail = (data) => {
    //     history.push({
    //         pathname: `/detail/${row.diamondType}-${row.ProductId}`
    //         });
    // }
    useEffect(() => {
        const finalData=`
             {
                 "certificate":"${row?.certificate}",
                 "shape":"${row?.shape}",
                 "carat":"${row?.carat}",
                 "color":"${row?.color}",
                 "clarity":"${row?.clarity}",
                 "stockId":"${row?.stockID}",
                 "ourPrice":"${row?.ourPrice}",
                 "supplierPrice":"${row?.supplierPrice}",
                 "supp_id":"${row?.sup_id}",
                 "curr":"${symbolname}",
                 "curr_rate":${conversionrate},
                 "search_result_id":"${row?.search_result_id}",
                 "userId":"${userinfo.id}",
                 "diamondType":"${row?.diamondType}",
                 "loginLogId":${Cookies.get("_LogId")}
             }
        `
         axios({
             method: "POST",
             url: `${NODE_API_URL}/AUS/fetchDiamondExpand`,
             headers: {
                 "Content-Type": "application/json",
                  "Authorization": `Bearer ${Cookies.get("cust_auth_token")}` 
             },
             data: finalData,
         }).then(function(res) {
             
         }).catch(error => {
             if(error?.response?.data?.message === "UnAuthorized!"){
                 var cookies = document.cookie.split(";");
                 for (var i = 0; i < cookies.length; i++) {
                     if(cookies[i] && !cookies[i].includes("view")){
                     var equals = cookies[i].indexOf("=");
                     var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
                     document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
                     }
                 }
                 window.location.replace(`${BASE_URL}/logout`);
             }
         })
     
 },[])
    const DetailSemiAprrove = () => {
        // setSemiAprroveModal(true)
        setSwalWarnOpen(true)
        setAlertMessage("Your account is under Lite Mode, to get further access please contact your sales representative. Thank you")
    }

    const shadeMappings = {
        "none": { label: "No.SH", tooltip: "Shade: NONE" },
        "brown": { label: "BR", tooltip: "Shade: Brown" },
        "light brown": { label: "L.BR", tooltip: "Shade: Light Brown" },
        "green": { label: "GR", tooltip: "Shade: Green" },
        "light green": { label: "L.GR", tooltip: "Shade: Light Green" },
        "grey": { label: "GREY", tooltip: "Shade: Grey" },
        "black": { label: "BLK", tooltip: "Shade: Black" },
        "pink": { label: "PINK", tooltip: "Shade: Pink" },
        "blue": { label: "BLUE", tooltip: "Shade: Blue" },
        "mix tinge": { label: "MIX", tooltip: "Shade: Mix Tinge" },
    };
    
    const renderShadeLabel = (shade) => {
        const mapping = shadeMappings[shade ? shade.toLowerCase() : ""];
        if (!mapping) return null;
    
        return (
            <span>
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="tooltip">{mapping.tooltip}</Tooltip>}
                >
                    <span className={`label label-light-primary label-light-shade_${shade.toLowerCase().replace(' ', '_')} label-pill label-inline label-sm mr-1 font-weight-bold px-2`}>{mapping.label}</span>
                </OverlayTrigger>
            </span>
        );
    };

    
    function handleClose(event, reason) {
        setShowVideo(false);
        setShowHeartImg(false);
        setShowAssetImg(false);
        setShowArrowImg(false);
    }

    useEffect(() => {
        let curr = "cur_inr"
        if(row.tracklistedcurrency === "INR"){
          curr = "cur_inr"
        }
        if(row.tracklistedcurrency === "USD"){
          curr = "cur_usd"
        }
        if(row.tracklistedcurrency === "AUD"){
          curr = "cur_aud"
        }
        if(row.tracklistedcurrency === "CAD"){
          curr = "cur_cad"
        }
        if(row.tracklistedcurrency === "HKD"){
          curr = "cur_hkd"
        }
        if(row.tracklistedcurrency === "CNY"){
          curr = "cur_cny"
        }
        if(row.tracklistedcurrency === "EUR"){
          curr = "cur_eur"
        }
        if(row.tracklistedcurrency === "GBP"){
          curr = "cur_gbp"
        }
        if(row.tracklistedcurrency === "NZD"){
          curr = "cur_nzd"
        }
        if(row.tracklistedcurrency === "JPY"){
          curr = "cur_jpy" 
        }
        if(row.tracklistedcurrency === "CHF"){
          curr = "cur_chf"
        }
        if(row.tracklistedcurrency === "BND"){
            curr = "cur_bnd"
        }
        if(row.tracklistedcurrency === "SGD"){
            curr = "cur_sgd"
        }
        if(row.tracklistedcurrency === "THB"){
            curr = "cur_thb"
        }
        if(row.tracklistedcurrency === "MYR"){
            curr = "cur_myr"
        }
        if(row.tracklistedcurrency === "DKK"){
            curr = "cur_dkk"
        }
        if(row.tracklistedcurrency === "SEK"){
            curr = "cur_sek"
        }
        axios({
            method: "POST",
            url: IVPCURRENCY,
            headers: { "Content-Type": "application/json" },
        }).then(response => {
            if(response && response.data){
                // console.log(response.data.data[0])
                response.data.data[0].cur_usd = 1
                const currentCurrency = response.data.data[0][curr];
                setCurrentCurrRate(Math.round(response.data.data[0][curr]*100)/100)
                // console.log(curr,currentCurrency)
            }
        })
    },[row.tracklistedcurrency])

    function openHeartModal() {
        setShowHeartImg(true)
    }

    function openAssetModal() {
        setShowAssetImg(true)
    }

    function openArrowModal() {
        setShowArrowImg(true)
    }

    const variantIcon = {
        success: CheckCircleIcon,
        error: ErrorIcon,
        warning: ErrorIcon,
    };
    const useStyles = makeStyles((theme) => ({
        close: {
            padding: theme.spacing(0.5),
        },
        success: {
            backgroundColor: green[600],
        },
        error: {
            backgroundColor: red[600],
        },
    }));
    function MySnackbarContentWrapper(props) {
        const classes = useStyles();
        const { className, message, onClose, variant, ...other } = props;
        const Icon = variantIcon[variant];
    
        return (
            <SnackbarContent
                className={clsx(classes[variant], className)}
                aria-describedby="client-snackbar"
                message={
                    <span id="client-snackbar" className={classes.message}>
                        <Icon
                            className={clsx(classes.icon, classes.iconVariant)}
                        />
                        {message}
                    </span>
                }
                action={[
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        onClick={onClose}
                    >
                        <CloseIcon className={classes.icon} />
                    </IconButton>,
                ]}
                {...other}
            />
        );
    }


    function numberWithCommas(x) {
        if(!x){
            return "0.00"
        }
        return x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const getshortlistedsymbol = (responsecurrency) => {
        let symbol = "$"
        if(responsecurrency === "INR"){
          symbol = "₹"
        }
        if(responsecurrency === "USD"){
          symbol = "$"
        }
        if(responsecurrency === "AUD"){
          symbol = "$"
        }
        if(responsecurrency === "CAD"){
          symbol = "$"
        }
        if(responsecurrency === "HKD"){
          symbol = "$"
        }
        if(responsecurrency === "CNY"){
          symbol = "¥"
        }
        if(responsecurrency === "EUR"){
          symbol = "€"
        }
        if(responsecurrency === "GBP"){
          symbol = "£"
        }
        if(responsecurrency === "NZD"){
          symbol = "$"
        }
        if(responsecurrency === "JPY"){
          symbol = "¥" 
        }
        if(responsecurrency === "CHF"){
          symbol = "₣"
        }
        if(responsecurrency === "SGD"){
            symbol = "$"
            
        } if(responsecurrency === "THB"){
            symbol = "฿"
            
        } if(responsecurrency === "MYR"){
            symbol = "RM"
            
        } if(responsecurrency === "BND"){
            symbol = "$"
            
        } if(responsecurrency === "DKK"){
            symbol = "kr."
            
        } if(responsecurrency === "SEK"){
            symbol = "kr"
            
        }
        return symbol
      }
    return(
       <>
        <div className={`expandedDetails natTab ${row.isfancy ? "fancyExpand" : "" }`} >
            <div className="row p-5">
                <div className="col-3 d-flex">
                    <ul className="img_thumb_box mt-4 ml-5 pl-0">
                    <ImageExpandPopupCommon data={row}/>
                    <VideoExpandPopupCommon  data={row} userinfo={userinfo}/>
                        
                        {row.heart ? (
                            <li className="img_thumb cursor-pointer" onClick={() => openHeartModal()}>
                            <SVG  width="40" src={toAbsoluteUrl("/media/General/heartImage.svg")} />
                                {/* <img  alt="img thumb" src="https://assets.diamondsoncall.com/assets/front/images/pd-5.png"/> */}
                            </li>
                            
                        ):('')}
                        {row.arrow ? (
                            <li className="img_thumb cursor-pointer" onClick={() => openArrowModal()}>
                                <SVG  width="40" src={toAbsoluteUrl("/media/General/arrowImage.svg")} />
                                {/* <img  alt="img thumb" src="https://assets.diamondsoncall.com/assets/front/images/pd-6.png"/> */}
                            </li>
                        ):('')}
                        {row.asset ? (
                            <li className="img_thumb cursor-pointer" onClick={() => openAssetModal()}>
                                <SVG  width="40" src={toAbsoluteUrl("/media/General/assetIcon.svg")} />
                                {/* <img  alt="img thumb" src="https://assets.diamondsoncall.com/assets/front/images/pd-3.png"/> */}
                            </li>
                        ):('')}
                    </ul>
                    <div className="img_box mt-4 ribbon ribbon-top">
                        {row.diamondType === "N" || row.diamondType === "W" ? null: <div className="ribbon-target custom-top-ribbon lab-ribbon" style={{top:"-2px",right:"20px"}} >Lab</div> }
                        <MainImageExpandedDetailsPopup data={row}/>
                    </div>
                </div>
                <div className="col-3">
                    <div className="row">
                        <div className="col-12 my-3 d-flex align-items-center">
                            <div className="mr-2 border-right  small">
                                <CertificateLink Certificate_link={row.certificate_link} lab={row.lab} certificate={row.certificate} withExternalLinkIcon  className={`mx-2 text-left`} />
                            </div>
                            <div className='small'>
                                {
                                    row.stoneStatus === 1 ?
                                        <a href={`/detail/${row.diamondType === "N" || row.diamondType === "W"?"N":"L"}-${row.stockID}?SearchId=0&searched_from=Tracklist`} target="_blank" rel="noreferrer" className=" d-inline-block mr-2 cursor-pointer">{`${row.diamondType === "N" || row.diamondType === "W"?"N":"L"}-${row.stockID}`}</a>
                                        : 
                                        <span className=" d-inline-block mr-2">{`${row.diamondType === "N" || row.diamondType === "W"?"N":"L"}-${row.stockID}`}</span>
                                }
                                <span>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                {
                                                row.availability === 'Memo' || row.availability === 'Hold'
                                                    ? `Business Process`
                                                    : row.availability === "Guaranteed"
                                                    ? `Guaranteed`
                                                    : row.availability === "Not Available"
                                                    ? `Not Available`
                                                    : row.availability === "Subject to Prior sale"
                                                    ? `Subject to Prior sale`
                                                    : `Unknown`
                                                }
                                            </Tooltip>
                                        }
                                    >
                                        <img
                                            className="img-fluid availIco mr-2"
                                            alt={row.availability}
                                            src={toAbsoluteUrl(`/media/svg/${
                                                row.availability === 'Memo' || row.availability === 'Hold'
                                                    ? `availability_primary.svg`
                                                    : row.availability === "Guaranteed"
                                                    ? `availability_success.svg`
                                                    : row.availability === "Not Available"
                                                    ? `availability_danger.svg`
                                                    : row.availability === "Subject to Prior sale"
                                                    ? `availability_warning.svg`
                                                    : `availability_blank_white.svg`
                                            }`)}
                                        />
                                    
                                    </OverlayTrigger>
                                </span>
                            </div>
                        </div>
                        <div className="col-10 align-self-center">
                            <span className="text-dark font-size-h3 font-weight-bold text-capitalize">{GenerateTitle(row)}</span>
                        </div>
                        <div className='col-12 advTag'>
                            {/* shade */}
                            {renderShadeLabel(row.shade)}
         
                            { row.milky && row.milky.toLowerCase() === "no milky" ?
                                <span>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                NO MILKY
                                            </Tooltip>
                                        }
                                    >
                                        <span className="label label-light-no_milky label-pill label-inline label-sm  mr-1 font-weight-bold px-2">NO.ML</span>
                                    </OverlayTrigger>
                                </span>
                            :
                                null
                            }

                            { row.milky && row.milky.toLowerCase() === "luster low" ?
                                <span>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                Luster Low
                                            </Tooltip>
                                        }
                                    >
                                        <span className="label label-light-luster_low label-pill label-inline label-sm  mr-1 font-weight-bold px-2">LL</span>
                                    </OverlayTrigger>
                                </span>
                            :
                                null
                            }

                            { row.milky && row.milky.toLowerCase() === "light milky" ?
                                <span>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                LIGHT MILKY
                                            </Tooltip>
                                        }
                                    >
                                        <span className="label label-light-light_milky label-pill label-inline label-sm  mr-1 font-weight-bold px-2">L.ML</span>
                                    </OverlayTrigger>
                                </span>
                            :
                                null
                            }

                            { row.milky && row.milky.toLowerCase() === "milky" ?
                                <span>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                MILKY
                                            </Tooltip>
                                        }
                                    >
                                        <span className="label label-light-milky label-pill label-inline label-sm  mr-1 font-weight-bold px-2">H.ML</span>
                                    </OverlayTrigger>
                                </span>
                            :
                                null
                            }

                                               {/* eyeclean */}
                                               { row.eyeClean && row.eyeClean.toLowerCase() === "yes" ?
                                <span>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                100% Eye Clean
                                            </Tooltip>
                                        }
                                    >
                                        <span className="label label-light-info label-pill label-inline label-sm  mr-1 font-weight-bold px-2">100% EC</span>
                                    </OverlayTrigger>
                                </span>
                            :
                                null
                            }
                            { row.eyeClean && row.eyeClean.toLowerCase() === "no" ?
                                <span>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                No Eye Clean
                                            </Tooltip>
                                        }
                                    >
                                        <span className="label label-light-danger label-pill label-inline label-sm  mr-1 font-weight-bold px-2">EC: NO</span>
                                    </OverlayTrigger>
                                </span>
                            :
                                null
                            }

                            {/* growthtype */}
                            { row.diamondType === "L" && (row.growthType && row.growthType === "CVD") ?
                                <span>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                CVD
                                            </Tooltip>
                                        }
                                    >
                                        <span className="label label-light-success label-pill label-inline label-sm  mr-1 font-weight-bold px-2">CVD</span>
                                    </OverlayTrigger>
                                </span>
                            :
                                null
                            }
                            { row.diamondType === "L" && (row.growthType && row.growthType === "HPHT") ?
                                <span>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                HPHT
                                            </Tooltip>
                                        }
                                    >
                                        <span className="label label-light-success label-pill label-inline label-sm  mr-1 font-weight-bold px-2">HPHT</span>
                                    </OverlayTrigger>
                                </span>
                            :
                                null
                            }
                            
                            { (row.diamondType === "L" ) && (row.treatment && row.treatment.toLowerCase() === "as grown") ? 
                                <span>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                As Grown
                                            </Tooltip>
                                        }
                                    >
                                        <span className="label label-light-success label-pill label-inline label-sm  font-weight-bold px-2">As-G</span>
                                    </OverlayTrigger>
                                </span>
                            : 
                                null
                            }
                            {/* natural treated */}
                            { (row.diamondType === "N" || row.diamondType === "W") && (row.treatment && row.treatment.toLowerCase() !== "none")  ? 
                                <span>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                Treated
                                            </Tooltip>
                                        }
                                    >
                                        <span className="label label-light-danger label-pill label-inline label-sm  font-weight-bold px-2">Tx</span>
                                    </OverlayTrigger>
                                </span>
                            : 
                                null
                            }
                        </div>
                        <div className="col-12 mt-3 d-flex">
                            <span className="font-size-lg text-dark-50"><span className="font-size-sm font-weight-bold text-capitalize">{language.MUI_LISTPRICE}: </span>${row.listPrice?row.listPrice:"0000"}</span>
                            <span className="font-size-lg text-dark-50 ml-8"><span className="font-size-sm font-weight-bold text-capitalize">{language.SEARCHGRID_SHIPPINGDAYS}: </span>{<> {row.shippingDays}{" "}{language.MUI_DAYS} </>}</span>
                        </div>
                        <div className="col-6 mt-5">
                            <div className="shortlist-price-box">
                                <h3 className="">
                                   Tracklisted Price
                                </h3>
                                <hr className="w-25 ml-0 my-2"/>
                                <div className='d-flex'>
                                    <div className="text-dark font-size-lg">({ row.tracklistedcurrency})</div>
                                    {row.stoneStatus !== 1 ? <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                Tracklisted Cost {getshortlistedsymbol(row.tracklistedcurrency)}/CT <b>{getshortlistedsymbol(row.tracklistedcurrency)}{numberWithCommas(row.tracklistedprice/row.carat)} </b>
                                                <br/>Tracklisted Cost price<b> {getshortlistedsymbol(row.tracklistedcurrency)}{numberWithCommas(row.tracklistedprice)}</b> 
                                            </Tooltip>
                                        }
                                    >
                                        <span className="ml-2 text-hover-primary cursor-pointer"><FaInfoCircle /></span>
                                    </OverlayTrigger> : null }
                                </div>
                                <span className="h1">
                                    <><span className="small">{getshortlistedsymbol(row.tracklistedcurrency)}</span >{numberWithCommas(row.tracklistedprice)}</>
                                </span>
                                <span className="d-flex small text-dark-50 align-items-center">Per Ct. <span className="font-size-sm text-dark-50 ml-2">{getshortlistedsymbol(row.tracklistedcurrency)}{numberWithCommas(row.tracklistedprice/row.carat)}</span></span>
                            </div>
                        </div>
                        <div className="col-6 mt-12 d-flex">
                            {row.stoneStatus === 1 ?
                                <div className="p-5">
                                    <h5 className="position-relative ">
                                        Current Cost Price
                                    </h5>
                                    <hr className="w-25 ml-0 my-2"/>
                                    {row.ourDiscount?row.ourDiscount.includes('-')?
                                    <div className="d-flex small text-danger align-items-center">{row.ourDiscount}% Off<span className="ml-8 text-dark font-size-lg">({ row.tracklistedcurrency})</span></div>:
                                    <div className="d-flex small text-success align-items-center">{row.ourDiscount}% PR<span className="ml-8 text-dark font-size-lg">({ row.tracklistedcurrency})</span></div>
                                    :<div className="d-flex small text-danger align-items-center">0.00% Off<span className="ml-8 text-dark">({ row.tracklistedcurrency})</span></div>}
                                    
                                    <div className='d-flex align-items-end'>
                                        <div>
                                            <span className="h4">
                                                <><span className="small">{getshortlistedsymbol(row.tracklistedcurrency)}</span >{numberWithCommas(row.tracklistedcurrentprice)}</>
                                            </span>
                                            <span className="d-flex small text-dark-50 align-items-center">Per Ct. <span className="font-size-sm text-dark-50 ml-2">{getshortlistedsymbol(row.tracklistedcurrency)}{numberWithCommas(row?.tracklistedcurrentprice/row?.carat)}</span></span>
                                        </div>
                                        <div>
                                            { 
                                                !row.priceIndicator
                                                ? ""
                                                : row.priceIndicator === "Green"
                                                ?
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip id="tooltip">
                                                                Tracklisted Cost {getshortlistedsymbol(row.tracklistedcurrency)}/CT <b>{getshortlistedsymbol(row.tracklistedcurrency)}{numberWithCommas(row.tracklistedprice/row.carat)} </b>
                                                                <br/>Tracklisted Cost price<b> {getshortlistedsymbol(row.tracklistedcurrency)}{numberWithCommas(row.tracklistedprice)}</b> 
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <span><FaLongArrowAltDown className='text-success cursor-pointer font-size-h3' /></span>
                                                    </OverlayTrigger>
                                                :
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip id="tooltip">
                                                                Tracklisted Cost {getshortlistedsymbol(row.tracklistedcurrency)}/CT <b>{getshortlistedsymbol(row.tracklistedcurrency)}{numberWithCommas(row.tracklistedprice/row.carat)} </b>
                                                                <br/>Tracklisted Cost price<b> {getshortlistedsymbol(row.tracklistedcurrency)}{numberWithCommas(row.tracklistedprice)}</b> 
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <span><FaLongArrowAltUp className='text-danger cursor-pointer  font-size-h3' /></span>
                                                    </OverlayTrigger>
                                            }
                                        </div>
                                    </div>
                                </div>
                            : null }  
                        </div>
                        <div className="col-12 mt-5">
                            <button type="button" className="btn btn-primary text-nowrap px-8 mr-3" onClick={() => BuyDiamond([{original:row}]) }><BsLightningFill /> {language.MUI_BUYDIAMONDBTN}</button>
                            <button type="button" className="btn btn-light text-nowrap" onClick={() =>  HoldDiamond([{original:row}]) }> 
                                <SVG className="ol mr-2" src={toAbsoluteUrl("/media/svg/icons/Custom/Hold.svg")} width="22.384" height="20.075" />
                                {language.MUI_HOLDDIAMONDBTN}
                            </button>
                            {
                               row.stoneStatus === 1 ? 
                                    <a href={`/detail/${row.diamondType === "N" || row.diamondType === "W"?"N":"L"}-${row.stockID}?SearchId=0&searched_from=Tracklist`} target="_blank" rel="noreferrer" className="btn ml-2 text-dark text-nowrap btn-light" > 
                                        <svg xmlns="http://www.w3.org/2000/svg" className="mr-2" width="22.384" height="20.075" viewBox="0 0 22.384 20.075">
                                            <g id="ic_view_details" transform="translate(-794 -623.819)">
                                                <path className="cls-1545654" id="Path_28337" data-name="Path 28337" d="M3.485.017a1.07,1.07,0,0,0-.634.436C2.739.627.071,5.681.037,5.781a.983.983,0,0,0,.013.56c.031.081,1.441,2.053,4.779,6.683,4.366,6.06,4.741,6.574,4.823,6.614a.385.385,0,0,0,.467-.093c.146-.141,9.53-12.848,9.624-13.029a.762.762,0,0,0,.068-.558c-.022-.087-.459-.938-1.442-2.808C17.595,1.674,16.933.43,16.9.386a.927.927,0,0,0-.44-.338L16.345,0,9.966,0C5.978,0,3.548,0,3.485.017ZM8.57,1.377,7.275,3.24c-.652.938-1.19,1.7-1.195,1.69S4.927,1.468,4.864,1.266L4.85,1.222H8.68Zm6.53-.049c-.263.749-1.2,3.386-1.2,3.389s-2.717-3.451-2.724-3.475.672-.02,1.979-.02h1.986l-.038.107ZM4.314,3.569c.352,1.065.642,1.941.642,1.948s-.761.013-1.691.013-1.691,0-1.691-.01.466-.894,1.036-1.974c.628-1.192,1.041-1.955,1.05-1.939s.3.894.656,1.961Zm7.117.029L12.924,5.52l-1.433.005H8.619L7.178,5.52,8.542,3.592c.75-1.061,1.37-1.926,1.379-1.923S10.608,2.54,11.431,3.6Zm5.824.064c.53,1.015.963,1.851.963,1.857s-.743.011-1.653.011c-1.563,0-1.652,0-1.643-.036s1.314-3.677,1.326-3.7.015-.01.024,0,.449.849.98,1.863ZM6.7,10.728c1.723,5.2,1.734,5.24,1.705,5.208S2.982,8.417,1.86,6.852l-.068-.094h3.6L6.7,10.728Zm6.452-3.957c0,.042-3.314,9.424-3.324,9.412S6.714,6.8,6.714,6.771,13.155,6.752,13.155,6.771Zm4.9.022c-.284.4-6.788,9.146-6.793,9.141s.719-2.072,1.605-4.6L14.48,6.754h1.8c1.737,0,1.8,0,1.772.036Z" transform="translate(793.998 623.819)" fill="#212529"/>
                                                <path className="cls-1545654" id="eye" d="M11.958,8.205A6.7,6.7,0,0,0,6.015,4.5,6.7,6.7,0,0,0,.073,8.206a.676.676,0,0,0,0,.61,6.7,6.7,0,0,0,5.943,3.705,6.7,6.7,0,0,0,5.943-3.706A.676.676,0,0,0,11.958,8.205ZM6.015,11.518A3.008,3.008,0,1,1,9.023,8.51,3.008,3.008,0,0,1,6.015,11.518Zm0-5.013a1.991,1.991,0,0,0-.529.079,1,1,0,0,1-1.4,1.4A2,2,0,1,0,6.015,6.505Z" transform="translate(804.354 631.374)" fill="#212529"/>
                                            </g>
                                        </svg>
                                        {language.MUI_DETAILS}
                                    </a>
                                :null
                            }
                        </div>
                    </div>
                </div>
                <div className="col-6">
                    <div className="row">
                        <div className="col-6 border-left pb-10">
                            { (row.isfancy) ? (
                                <>
                                    <div className="font-size-h5 text-dark font-weight-bold mb-3 mt-5">Fancy Color Details </div>
                                    <div className="row mb-1">
                                        <div className="col-6">Intensity</div>
                                        <div className="col-6 font-weight-bold">  {row.fancy_intensity ? row.fancy_intensity : "-"} </div>
                                    </div>
                                    <div className="row mb-1">
                                        <div className="col-6">Overtone</div>
                                        <div className="col-6 font-weight-bold">  {row.fancy_overtone ? row.fancy_overtone : "-"} </div>
                                    </div>
                                    <div className="row mb-5">
                                        <div className="col-6">Color</div>
                                        <div className="col-6 font-weight-bold">  {row.fancy_color ? row.fancy_color : "-"} </div>
                                    </div>
                                </>
                            ):('')}
                            <div className="font-size-h5 text-dark font-weight-bold mb-3 mt-5">{language.MUI_PARAM_DETAILS} </div>
                            <div className="row mb-1">
                                <div className="col-6"> {language.MUI_MEASUREMENT} </div>
                                <div className="col-6 font-weight-bold"> {row.length} * {row.width} * {row.height} </div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-6 "> {language.MUI_TABLE} (%) </div>
                                <div className="col-6 font-weight-bold"> {row.tablePercent ? row.tablePercent : "-"} </div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-6"> {language.MUI_DEPTH} (%)</div>
                                <div className="col-6 font-weight-bold"> {row.depthPercent ? row.depthPercent : "-"} </div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-6">
                                    {language.MUI_CRHEIGHT}
                                </div>
                                <div className="col-6 font-weight-bold">
                                    {row.crownHeight ? row.crownHeight : "-"}
                                </div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-6">
                                    {language.MUI_CRANGLE}
                                </div>
                                <div className="col-6 font-weight-bold">
                                    {row.crownAngle ? row.crownAngle : "-"}
                                </div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-6">
                                {language.MUI_PAVHEIGHT}
                                </div>
                                <div className="col-6 font-weight-bold">
                                    {row.pavilionHeight ? row.pavilionHeight : "-"}
                                </div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-6">
                                    {language.MUI_PAVANGLE}
                                </div>
                                <div className="col-6 font-weight-bold">
                                    {row.pavilionAngle ? row.pavilionAngle : "-"}
                                </div>
                            </div>
                            
                            <div className="row mb-1">
                                <div className="col-6">
                                    {language.MUI_RATIO}
                                </div>
                                <div className="col-6 font-weight-bold">
                                {row.ratio?row.ratio.toFixed(2):"-"}
                                </div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-6">
                                    {language.MUI_GIRDLE}
                                </div>
                                <div className="col-6 font-weight-bold">
                                {row.girdle ? row.girdle : "-"}
                                </div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-6">
                                    {language.MUI_GIRDLECONDTION}
                                </div>
                                <div className="col-6 font-weight-bold">
                                {row.girdleCondition  ? row.girdleCondition : "-"}
                                </div>
                            </div>
                            
                        </div>
                        <div className="col-6 border-left">
                                <h5 className="font-size-h5 text-dark font-weight-bold mb-3  mt-5">{language.MUI_ADDITIONALDETAILS}</h5>
                                <div className="row mb-1">
                                    <div className="col-6">
                                        {language.MUI_BRAND}
                                    </div>
                                    <div className="col-6 font-weight-bold">
                                        {row.brand ? row.brand : "-"}
                                    </div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-6">
                                        {language.MUI_SHADE}
                                    </div>
                                    <div className="col-6 font-weight-bold">
                                        {row.shade  ? row.shade : "-"}
                                    </div>
                                </div>
                                <hr className="w-75 ml-0"/>
                                <div className="row mb-1">
                                    <div className="col-6">
                                        {language.MUI_MILKY}
                                    </div>
                                    <div className="col-6 font-weight-bold">
                                        {row.milky  ? row.milky : "-"}
                                    </div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-6">
                                        {language.MUI_EYECLEAN}
                                    </div>
                                    <div className="col-6 font-weight-bold">
                                        {row.eyeClean ? row.eyeClean : "-"}
                                    </div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-6"> {language.MUI_CULET} </div>
                                    <div className="col-6 font-weight-bold"> {row.culet  ? row.culet : "-"} </div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-6">{language.MUI_KEY}</div>
                                    <div className="col-6 font-weight-bold">{row.keyToSymbol ? row.keyToSymbol : "-" }</div>
                                </div>
                                    {row.diamondType === "L" ? 
                                        <>
                                            { (row.growthType) ? (
                                                <div className="row mb-1">
                                                    <div className="col-6"> {language.MUI_GROWTHTYPE} </div>
                                                    <div className="col-6 font-weight-bold">  {row.growthType ? row.growthType : "-"} </div>
                                                </div>
                                            ):('')}
                                            { (row.treatment) ? (
                                                <div className="row mb-1">
                                                    <div className="col-6"> {language.MUI_TREATMENT} </div>
                                                    <div className="col-6 font-weight-bold">  {row.treatment} </div>
                                                </div>
                                            ):(row.treatment) ? (
                                                <div className="row mb-1">
                                                    <div className="col-6"> {language.MUI_TREATMENT} </div>
                                                    <div className="col-6 font-weight-bold">  {row.treatment ? row.treatment : "-"} </div>
                                                </div>
                                            ):('')}
                                        </>
                                    :   
                                        <>
                                            { (row.origin) ? (
                                                <div className="row mb-1">
                                                    <div className="col-6"> {language.MUI_ORIGIN} </div>
                                                    <div className="col-6 font-weight-bold">  {row.origin ? row.origin : "-"} </div>
                                                </div>
                                            ):('')}
                                            {/* { (row.brand) ? (
                                                <div className="row mb-1">
                                                    <div className="col-6"> {language.MUI_BRAND} </div>
                                                    <div className="col-6 font-weight-bold">  {row.brand ? row.brand : "-"} </div>
                                                </div>
                                            ):('')} */}
                                            { (row.treatment) ? (
                                                <div className="row mb-1">
                                                    <div className="col-6"> {language.MUI_TREATMENT} </div>
                                                    <div className="col-6 font-weight-bold">  {row.treatment ? row.treatment : "-"} </div>
                                                </div>
                                            ):('')}
                                        </>
                                    }
                            </div>
                    </div>
                </div>
            </div>
        </div>
                    
          {/* {confirmPopupShow === true && <ConfirmGoods show={confirmPopupShow} onHide={handleConfirmPopupClose} {...row} />}  
          {holdPopupShow === true && <HoldPopup show={holdPopupShow} onHide={handleHoldPopupClose} {...row} />}   */}
           <SwalWarn show={swalWarnOpen} message={alertMessage} onClose={() => setSwalWarnOpen(false)} /> 

            <Modal
                show={showHeartImg}
                onHide={handleClose}
                size="lg"
            >
                <Modal.Body className="text-center">
                    <img src={row.heart} alt={row.shape} className="img-fluid" />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={showArrowImg}
                onHide={handleClose}
                size="lg"
            >
                <Modal.Body className="text-center">
                    <img src={row.arrow} alt={row.shape} className="img-fluid" />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={showAssetImg}
                onHide={handleClose}
                size="lg"
            >
                <Modal.Body className="text-center">
                    <img src={row.asset} alt={row.shape} className="img-fluid" />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={handleClose}
                    >
                    Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <VideoIframe
                showVideo={showVideo}
                handleClose={handleClose}
                src={row.diamondType === "N" || row.diamondType === "W" ? `https://pro360video.com/video.php?refno=${row.Certi_NO}` : `https://pro360video.com/labgrown.php?refno=${row.Certi_NO}` }
                // src={row.ext_video || ""}
                data={row}
           />
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                open={Toast}
                autoHideDuration={2000}
                onClose={handleClose}
            >
                <MySnackbarContentWrapper
                    onClose={handleClose}
                    variant="success"
                    message={wishlistmessage}
                />
            </Snackbar>
      </>
    )
}