import React, {useMemo,useState,useEffect} from 'react'
import { useLocation,useHistory } from "react-router";

import { createPortal } from 'react-dom';
import {useTable, useGlobalFilter,useSortBy, usePagination, useRowSelect,useExpanded } from 'react-table';
import { IoGitCompareOutline,IoGridOutline } from "react-icons/io5";
import { FaSortDown, FaSortUp, FaEllipsisH,FaRegFileExcel } from "react-icons/fa";
import {BsLightningFill } from "react-icons/bs";
import { TbTableOptions } from "react-icons/tb";
import { IoMdClose } from "react-icons/io";
import { Dropdown, Modal,Tooltip,OverlayTrigger } from 'react-bootstrap';
import SVG from "react-inlinesvg";
import { MockSearchResultColumn } from './searchResult/Columns'; 
import { CheckBox } from '../tableFilter/CheckBox';
import  ExpandedDetails from "./searchResult/ExpandedDetails"
import { ConfirmGoods,HoldPopup,SwalWarn,SwalError,CompareDiamond } from "../Popup";
import SearchTableSkeleton from './SearchTableSkeleton'
import axios from "axios";
import { SwipeableDrawer } from '@material-ui/core';
import ModifiedSearchOffcanvas from './ModifiedSearchOffcanvas'
import PerfectScrollbar from "react-perfect-scrollbar";
import { toAbsoluteUrl } from '../../_helpers';
import { NODE_API_URL,BASE_URL } from '../../../env_config';
import Excel from "exceljs";
import FileSaver from "file-saver"
import Cookies from "js-cookie";
import { TracklistStone } from '../Popup/TracklistPopup';
import Sorting from './Sorting';
import InfiniteTableSkeleton from './InfiniteTableSkeleton';
import { SetCookies } from '../../_helpers/Cookies';
const perfectScrollbarOptions = {
    wheelSpeed: 1,
    wheelPropagation: false,
};




export const SearchResult = ({conversionrate,symbol,language,isprofilecomplete,handleClick,symbolname,userinfo}) => {
    const location = useLocation();
    const isStockSearch = location.pathname.includes('stock-search');
    const history = useHistory()
    const [sortobj,setSortObj] = useState({id:"",sortorder:""})
    const [show,setShow] = useState(false)
    const [diamonddetails,setDiamondDetail] = useState([])
    const [isDropdown, setIsDropdown] = useState(false);
    const [swipeableDrawer, setSwipeableDrawer] = useState({
      right: false,
    });
    const scrollRef = React.useRef(null);
    useEffect(() => {
        const handleResize = () => {
            setIsDropdown(window.innerWidth < 768);
        };
    
        handleResize(); // Check initial size
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const toggleDrawer = (side, open) => event => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
          return;
        }
    
        setSwipeableDrawer({ ...swipeableDrawer, [side]: open });
      };

    const [CustomizeTable, setCustomizeTable] = useState(false);
    const [swalWarnOpen,setSwalWarnOpen] = useState(false)
    const [swalErrorOpen,setSwalErrorOpen] = useState(false)
    const [alertMessage,setAlertMessage] = useState()
    const [totalcarat,setTotalCarat] = useState(0)
    const [totalinrcar,setTotalInrCar] = useState(0)
    const [totalourprice,setTotalOurPrice] = useState(0)
    const [diamondselected,setDiamondSelected] = useState([])
    const [diamondcount,setDiamondcount] = useState(0)
    const [buypopup,setBuyPopUp] = useState(false)
    const [comparePopup,setComparePopup] = useState(false)
    const [holdpopup,setHoldPopUp] = useState(false)
    const [buypopupcinr,setBuyPopUpInr] = useState(0)
    const [buypopupctotal,setBuyPopUpTotal] = useState(0)
    const [StockAPI, setStockAPI] = useState(location.state.searchresult);
    const [pagenumber,setPageNumber] = useState(location.state.page || 1)
    const [loader,setLoader] = useState(true)
    const [infiniteLoader,setInfiniteLoader] = useState(false)
    const [updatecount,setUpdateCount] = useState(false)
    const [countloader,setCountLoader] = useState(true)
    const [selectedRows, setSelectedRows] = useState({});
    // const [order, setOrder] = React.useState('asc');
    // const [orderBy, setOrderBy] = React.useState('OurPrice');
    const [modifymsg,setModifyMessage] = useState("")
    // const [selected, setSelected] = React.useState([]);
    // const isSelected = (name) => selectedFlatRows.indexOf(name) !== -1;
    const [dimondType] =useState(StockAPI.length > 0 && StockAPI[0].diamondType? StockAPI[0].diamondType:"N")

    // const stoneCount = useMemo(()=>  localStorage.getItem("stoneCount"), [localStorage.getItem("stoneCount")])
    const [stoneCount,setStoneCount] = useState(0)
    const [disablesorting,setDisableSorting] = useState(false)
    const ReloadPage = () => {
        setLoader(true)
        const fancy = location.state.oldvalues.diamond_type === "N"?Cookies.get("fancy")?1:0:Cookies.get("fancybasic")?1:0
        window.Nat = location.state.oldvalues;
        let len = Object.keys(location.state.oldvalues).length;
        const temp = location.state.oldvalues;
        let data = "";
        for (let key in temp) {
            len--;
            let arr = temp[key];
            if (len) {
                if (Array.isArray(arr)) {
                    let str = "";
                    str += "'" + arr.join("','") + "'";
                    data += `{"FilterName": "${key}","FilterValue":"${str}" }, `;
                } else {
                    data += `{"FilterName": "${key}","FilterValue": "'${temp[key]}'"},`;
                }
            } else {
                if (Array.isArray(arr)) {
                    let str = "";
                    str += "'" + arr.join("','") + "'";
                    data += `{"FilterName": "${key}","FilterValue":"${str}" } `;
                } else {
                    data += `{"FilterName": "${key}","FilterValue": "'${temp[key]}'"}`;
                }
            }
        }
        const finalData = `{ "JsonData":  [${data}] , "CustomerId": "${userinfo.id}", "ConsumerId":${ id === "" ? "-1" : id }, "WLDiamondType": "${location.state.oldvalues.diamond_type}" ,"page":1, "conversionrate":"${conversionrate}","curr":"${symbolname}","fancy":"${fancy}","loginLogId":${Cookies.get("_LogId")}}`;
        if(location.state.oldvalues.diamond_type === "N"){
            localStorage.setItem("modifysearch",JSON.stringify({...location.state.oldvalues}))
        }
        else{
            localStorage.setItem("modifysearchlab",JSON.stringify({...location.state.oldvalues}))
        }
        axios({
            method: "POST",
            url: `${NODE_API_URL}/AUS/searchDiamond`,
            headers: {
                "Content-Type": "application/json",
                 "Authorization": `Bearer ${Cookies.get("cust_auth_token")}` 
            },
            data: finalData,
        })
        .then(function(res) {
            // console.log("SORTTTT")
            setStockAPI(res.data.data)
            setPageNumber(1)
            history.push({
                pathname: `/search-result/${StockAPI[0].diamondType}`,
                state: {
                    searchresult:res.data.data,
                    oldvalues:location.state.oldvalues,
                    page:1
                },
                    // state: decompress(res.data.ProductDetails),
                });
                setTimeout(() => {
                    setLoader(false)
                   },1000) 
        })
        .
    catch(error => {
      if(error?.response?.data?.message === "UnAuthorized!"){
        var cookies = document.cookie.split(";");
      for (var i = 0; i < cookies.length; i++) {
        if(cookies[i] && !cookies[i].includes("view")){
          var equals = cookies[i].indexOf("=");
          var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
          document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        }
      }
      
      window.location.replace(`${BASE_URL}/logout`);
    }
    })
}
const ReloadStockPage = () => {
    setLoader(true)
    axios({
        method: "get",
        url: `${NODE_API_URL}/AUS/fetchDiamondsByCertificatesOrStockNumber`,
        headers: {
          "Content-Type": "application/json",
           "Authorization": `Bearer ${Cookies.get("cust_auth_token")}` 
      },
        params: location.state.finalData,
      })
      .then(function(res) {
        setStockAPI(res.data.data)
        history.push({
            pathname: "/search-result/stock-search",
            state: {
                searchresult:res.data.data,
                oldvalues:null,
                finalData:location.state.finalData
            },
                // state: decompress(res.data.ProductDetails),
            });
            setTimeout(() => {
                setLoader(false)
               },1000) 
      })
      .catch(error => {
        if(error?.response?.data?.message === "UnAuthorized!"){
          var cookies = document.cookie.split(";");
        for (var i = 0; i < cookies.length; i++) {
          if(cookies[i] && !cookies[i].includes("view")){
            var equals = cookies[i].indexOf("=");
            var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
          }
        }
        
        window.location.replace(`${BASE_URL}/logout`);
      }
      })
}
useEffect(() => {
    window.onbeforeunload = (event) => {
        sessionStorage.setItem("pageReloaded","1")
      };
  }, []);
useEffect(() => {
    const getpagereload = sessionStorage.getItem("pageReloaded")
    if(getpagereload === "1"){
       if(!location.state.oldvalues){
        ReloadStockPage()
       }else{
        ReloadPage()
       }
        sessionStorage.setItem("pageReloaded","0")
    }
},[])
    useEffect(() => {
        setStockAPI(location.state.searchresult)
        if(!location.state.oldvalues){
            setStoneCount(location.state.searchresult.length)
            setDisableSorting(true)
        }
        else{
            setDisableSorting(false)
        }
    },[location.state])
    useEffect(() => {
        if(location.state.oldvalues){
        const fancy = location.state.oldvalues.diamond_type === "N"?Cookies.get("fancy")?1:0:Cookies.get("fancybasic")?1:0
        setCountLoader(true)
        let len = Object.keys(location.state.oldvalues).length;
        const temp = location.state.oldvalues;
        let data = "";
        for (let key in temp) {
            len--;
            let arr = temp[key];
            if (len) {
                if (Array.isArray(arr)) {
                    let str = "";
                    str += "'" + arr.join("','") + "'";
                    data += `{"FilterName": "${key}","FilterValue":"${str}" }, `;
                } else {
                    data += `{"FilterName": "${key}","FilterValue": "'${temp[key]}'"},`;
                }
            } else {
                if (Array.isArray(arr)) {
                    let str = "";
                    str += "'" + arr.join("','") + "'";
                    data += `{"FilterName": "${key}","FilterValue":"${str}" } `;
                } else {
                    data += `{"FilterName": "${key}","FilterValue": "'${temp[key]}'"}`;
                }
            }
        }
        const finalData = `{ "JsonData":  [${data}] , "CustomerId": "${userinfo.id}", "ConsumerId":${ id === "" ? "-1" : id }, "WLDiamondType": "${location.state.oldvalues.diamond_type}" ,"page":1, "conversionrate":"${conversionrate}","fancy":"${fancy}" ,"search_result_id":${StockAPI[0].search_result_id}}`;
        // localStorage.setItem("modifysearch",JSON.stringify({...location.state.oldvalues}))
        axios({
            method: "POST",
            url: `${NODE_API_URL}/AUS/countDiamond`,
            headers: {
                "Content-Type": "application/json",
                 "Authorization": `Bearer ${Cookies.get("cust_auth_token")}` 
            },
            data: finalData,
        })
        .then(function(rescount) {
            // setStoneCount(JSON.stringify(rescount.data.data));
            setCountLoader(false)
            setStoneCount(rescount.data.count)
        }).
        catch(error => {
          if(error?.response?.data?.message === "UnAuthorized!"){
            var cookies = document.cookie.split(";");
          for (var i = 0; i < cookies.length; i++) {
            if(cookies[i] && !cookies[i].includes("view")){
              var equals = cookies[i].indexOf("=");
              var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
              document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
            }
          }
          
          window.location.replace(`${BASE_URL}/logout`);
        }
        })
        }
        else{
            setStoneCount(location.state.searchresult.length)
            setCountLoader(false)
        }
    },[updatecount])
    const userData = useMemo(() => localStorage.getItem("userData"), []);
    const id = React.useMemo(() => localStorage.getItem("C_id"), []);
    const user = JSON.parse(userData);

    // const initialState = {
    //     sortBy: [
    //       {
    //         id: "OurPrice",
    //         desc: false
    //       }
    //     ],
    //   };
    // function descendingComparator(a, b, orderBy) {
    //     if (b[orderBy] < a[orderBy]) {
    //       return -1;
    //     }
    //     if (b[orderBy] > a[orderBy]) {
    //       return 1;
    //     }
    //     return 0;
    //   }
      function GetRatio(row) {
        let $ratioval
        if (row.C_Shape !== 'ROUND') {
            $ratioval = (row.C_Length / row.C_Width).toFixed(2);
        } else {
            $ratioval = '-';
        }
        return $ratioval
    }
    // function getComparator(order, orderBy) {
    //     return order === 'desc'
    //       ? (a, b) => descendingComparator(a, b, orderBy)
    //       : (a, b) => -descendingComparator(a, b, orderBy);
    //   }
      
    //   function stableSort(array, comparator) {
    //     const stabilizedThis = array.map((el, index) => [el, index]);
    //    stabilizedThis.sort((a, b) => {
    //       const order = comparator(a[0], b[0]);
    //       if (order !== 0) return order;
    //       return a[1] - b[1];
    //     });
    //     return stabilizedThis.map((el) => el[0]);
    //   }

    const DetailSemiAprrove = () => {
        // setSemiAprroveModal(true)
        setSwalWarnOpen(true)
        setAlertMessage("Your account is under Lite Mode, to get further access please contact your sales representative. Thank you")
    }

    const handleClose = () => setCustomizeTable(false);
    useEffect(() => {
        if(StockAPI.length !== parseFloat(stoneCount) && pagenumber !== 1){
            HandlePagination(pagenumber);
        }
    }, [pagenumber]);
    const onScroll = () => {
        const div = scrollRef.current;
        if (!div) return;
        // console.log(Math.round(div.scrollTop + div.clientHeight), "SUMMMMM");
        // console.log(div.scrollHeight, "div.scrollHeight");
        if(!infiniteLoader){
            if (Math.round(div.scrollTop + div.clientHeight + 1) >= div.scrollHeight && StockAPI.length > 0) {
            setPageNumber(prevPage => prevPage + 1);
        }
        }
    };


    useEffect(() => {
        const div = scrollRef.current;
        if (loader === false && div && !isStockSearch && !infiniteLoader) {
            div.addEventListener('scroll', onScroll);
            return () => div.removeEventListener('scroll', onScroll);
        }
    }, [scrollRef, StockAPI,loader, isStockSearch,infiniteLoader]);

    const ChangeView = () => {
        SetCookies("view","search-grid")
        history.push({
          pathname: `/search-grid/${StockAPI[0].diamondType}`,
        //   state: stableSort(StockAPI, getComparator(order, orderBy)),
          state: {
            searchresult:location.state.searchresult,
            oldvalues:location.state.oldvalues,
            page:1,
            sortobj:sortobj,
            finalData:location.state.finalData
        }
              // state: decompress(res.data.ProductDetails),
          });
      }
    //   const modifiedSearch = () => {
    //     const searchLink = StockAPI[0].diamondType === "L" ? "/labsearchdiamond" : "/searchdiamond" 
    //       history.push({
    //         pathname: searchLink,
    //         state: StockAPI,
    //             // state: decompress(res.data.ProductDetails),
    //         });
    //     }
        const CompareDiam = (selectedFlatRows) => {
            if(selectedFlatRows.length === 0){
                alert("Please Select Diamonds")
                return
            }
            if(selectedFlatRows.length === 1){
                alert("Please Select Minimum 2 Diamonds")
                return
            }
            if(selectedFlatRows.length > 4){
                alert("Only 4 diamonds allowed")
                return
            }
            let temptotalcarat = 0
            let temptotalinrcar = 0
            let temptotalourprice = 0
            let diamondcount = 0
           const selectedvalues = selectedFlatRows.map(value => {
                const finddata = StockAPI.find(val => value.original.stockID.toString() === val.stockID.toString())
                    diamondcount += 1
                    temptotalcarat += finddata.carat
                    temptotalourprice += finddata.ourPrice
                    temptotalinrcar += (diamondcount>0?finddata.ourRate/diamondcount:0)
                return {
                    ...finddata,
                    temptotalcarat:temptotalcarat,
                    temptotalinrcar:temptotalinrcar,
                    temptotalourprice:temptotalourprice
                }
            })
            setDiamondSelected(selectedvalues)
            setComparePopup(true)
            setDiamondcount(diamondcount)
            setBuyPopUpInr(temptotalcarat > 0 ?temptotalourprice/temptotalcarat:0)
            setBuyPopUpTotal(temptotalourprice)
          }
      const BuyDiamond = (selectedFlatRows) => {
        if(selectedFlatRows.length === 0){
            alert("Please Select Diamonds")
            return
        }
        let temptotalcarat = 0
        let temptotalinrcar = 0
        let temptotalourprice = 0
        let diamondcount = 0
        const selectedvalues = selectedFlatRows.map(value => {
            const finddata = StockAPI.find(val => value.original.stockID.toString() === val.stockID.toString())
            // if(finddata.country === "INDIA"){
                diamondcount += 1
                temptotalcarat += finddata.carat
                temptotalourprice += finddata.ourPrice
                temptotalinrcar += (diamondcount>0?finddata.ourRate/diamondcount:0)
            // }
            return {
                ...finddata,
                temptotalcarat:temptotalcarat,
                temptotalinrcar:temptotalinrcar,
                temptotalourprice:temptotalourprice
            }
        })
        setDiamondSelected(selectedvalues)
        setDiamondcount(diamondcount)
        setBuyPopUp(true)
        setBuyPopUpInr(temptotalcarat > 0 ?temptotalourprice/temptotalcarat:0)
        setBuyPopUpTotal(temptotalourprice)
      }
      const HoldDiamond = (selectedFlatRows) => {
        if(selectedFlatRows.length === 0){
            alert("Please Select Diamonds")
            return
        }
        let temptotalcarat = 0
        let temptotalinrcar = 0
        let temptotalourprice = 0
        let diamondcount = 0
       const selectedvalues = selectedFlatRows.map(value => {
            const finddata = StockAPI.find(val => value.original.stockID.toString() === val.stockID.toString())
            if(finddata.can_hold === 1){
                diamondcount += 1
                temptotalcarat += finddata.carat
                temptotalourprice += finddata.ourPrice
                temptotalinrcar += (diamondcount>0?finddata.ourRate/diamondcount:0)
            }
            return {
                ...finddata,
                temptotalcarat:temptotalcarat,
                temptotalinrcar:temptotalinrcar,
                temptotalourprice:temptotalourprice
            }
        })
        setDiamondSelected(selectedvalues)
        setHoldPopUp(true)
        setDiamondcount(diamondcount)
        setBuyPopUpInr(temptotalcarat > 0 ?temptotalourprice/temptotalcarat:0)
        setBuyPopUpTotal(temptotalourprice)
      }
      const ExcelDownload = async(selectedFlatRows) => {
        if(selectedFlatRows.length === 0){
            setSwalWarnOpen(true)
            setAlertMessage("Please Select at least One Record ...!!")
            return
        }
        
        const workbook = new Excel.Workbook();
        const worksheet = workbook.addWorksheet("My Sheet");
        worksheet.columns = [
            {header: 'Stone ID', key: 'Stone ID', width: 15},
            {header: 'Shape', key: 'Shape', width: 15}, 
            {header: 'Carat', key: 'Carat', width: 15},
            {header: 'Color', key: 'Color', width: 15},
            {header: 'Clarity', key: 'Clarity', width: 15}, 
            {header: 'Cut', key: 'Cut', width: 15},
            {header: 'Pol', key: 'Pol', width: 15},
            {header: 'Sym', key: 'Sym', width: 15}, 
            {header: 'Flo', key: 'Flo', width: 15},
            {header: 'Measurement', key: 'Measurement', width: 15},
            {header: 'Rap Rate', key: 'Rap Rate', width: 15}, 
            {header: 'Dis %', key: 'Dis %', width: 15},
            {header: '$/Ct', key: '$/Ct', width: 15},
            {header: 'Total $', key: 'Total $', width: 15}, 
            {header: 'Lab', key: 'Lab', width: 15},
            {header: 'Report No', key: 'Report No', width: 15},
            {header: 'Depth', key: 'Depth', width: 15}, 
            {header: 'Table', key: 'Table', width: 15},
            {header: 'CA', key: 'CA', width: 15},
            {header: 'CH', key: 'CH', width: 15}, 
            {header: 'PA', key: 'PA', width: 15},
            {header: 'PH', key: 'PH', width: 15},
            {header: selectedFlatRows[0].original.diamond_type === "L"?"Growth type":"Origin", key: selectedFlatRows[0].original.diamond_type === "L"?"Growth type":"Origin", width: 15}, 
            {header: 'Treatment', key: 'Treatment', width: 15},
            {header: 'Key To Sym', key: 'Key To Sym', width: 15},
            {header: 'Eye Clean', key: 'Eye Clean', width: 15}, 
            {header: 'Country', key: 'Country', width: 15},
            {header: 'Certificate URL', key: 'Certificate URL', width: 15},
            {header: 'Image Url', key: 'Image Url', width: 15}, 
            {header: 'Video Url', key: 'Video Url', width: 15},
            {header: 'Type', key: 'Type', width: 15},
           ]
           worksheet.getRow(1).fill = {
            type: 'pattern',
            pattern:'solid',
            fgColor:{ argb:'2830D4' }
            }
            worksheet.getRow(1).height = 26.4
            worksheet.getRow(1).font = {color: {argb: "FFFFFF"}};
        //    selectedFlatRows.map((value) => {
            for(let i = 0;i < selectedFlatRows.length; i++){
            let value = selectedFlatRows[i]
             let measurement = ""
            if(value.original.length){
                measurement += parseFloat(value.original.length).toFixed(2)
            }
            if(value.original.width){
                measurement += `*${parseFloat(value.original.width).toFixed(2)}`
            }
            if(value.original.height){
                measurement += `*${parseFloat(value.original.height).toFixed(2)}`
            }
            let ExcelObj = {
                "Stone ID":`${value.original.diamondType}-${value.original.stockID}`,
                "Shape":value.original.shape,
                "Carat":parseFloat(value.original.carat).toFixed(2),
                "Color":value.original.color,
                "Clarity":value.original.clarity,
                "Cut":value.original.cut,
                "Pol":value.original.polish,
                "Sym":value.original.symmetry,
                "Flo":value.original.fluorescence,
                "Measurement":measurement,
                "Rap Rate":value.original.listPrice,
                "Dis %":parseFloat(value.original.ourDiscount).toFixed(2),
                "$/Ct":parseFloat(value.original.ourRate).toFixed(2),
                "Total $":parseFloat(value.original.ourPrice).toFixed(2),
                "Lab":value.original.lab,
                "Report No":value.original.certificate,
                "Depth":parseFloat(value.original.depthPercent).toFixed(2),
                "Table":parseFloat(value.original.tablePercent).toFixed(2),
                "CA":parseFloat(value.original.crownAngle).toFixed(2),
                "CH":parseFloat(value.original.crownHeight).toFixed(2),
                "PA":parseFloat(value.original.pavilionAngle).toFixed(2),
                "PH":parseFloat(value.original.pavilionHeight).toFixed(2),
            }
            if(value.original.growthType && value.original.diamondType === "L"){
                ExcelObj["Growth type"] = value.original.growthType
            }
            else{
                ExcelObj["Origin"] = value.original.origin
            }
            ExcelObj["Treatment"] = value.original.treatment
            ExcelObj["Key To Sym"] = value.original.keyToSymbol
            ExcelObj["Eye Clean"] = value.original.eyeClean
            ExcelObj["Country"] = value.original.location
            ExcelObj["Certificate URL"] = value.original.certificate_link
            let image = ""
            let video = ""
            if(value.original.video){
                video = value.original.video
           
            }
            if(value.original.image){
                image = value.original.image
            }
            ExcelObj["Image Url"] = image
            ExcelObj["Video Url"] = video
            ExcelObj["Type"] = value.original.diamondType === "L"?"Lab Grown":"Natural"
            worksheet.addRow(ExcelObj)
            }
        // })  
                let date = new Date() //10 May 2019, 3:30:20 PM
        date = new Date(date.getTime())
        
        let dateStr = date.toLocaleDateString("en-GB", { day: "2-digit", month: "2-digit", year: "numeric" }) // 10/05/19

        let arr = dateStr.split("/")// [ '10', '05', '19' ]
        let d = arr[0]; //e.g. 10 
        let m = arr[1]; //e.g. 5 
        let y = arr[2]; //e.g. 19

        let timeStr = date.toLocaleTimeString("en-GB", { hour12: false, hour: "2-digit", minute: "2-digit", second: "2-digit" }) //
        let arr2 = timeStr.split(":") // 15:30:20
        let H = arr2[0]; //e.g. 15
        let i = arr2[1]; //e.g. 30
        let s = arr2[2]; //e.g. 20
        worksheet.eachRow(function (Row, rowNum) {
            /** Row.alignment not work */
            // Row.alignment = { horizontal: 'left' }
    
            Row.eachCell(function (Cell, cellNum) {
                /** cell.alignment not work */
                if (rowNum == 1) {
                    Cell.alignment = {
                        vertical: 'middle',
                        horizontal: 'center'
                    }
                }else{
                    Cell.alignment = {
                        vertical: 'middle',
                        horizontal: 'left'
                    }
                }
            })
        })
        
        workbook.xlsx.writeBuffer()
  .then(buffer => FileSaver.saveAs(new Blob([buffer]), `Stock${y}-${m}-${d}-${H}-${i}-${s}.xlsx`))
  .catch(err => {})
        
      }
    const renderRowSubComponent = React.useCallback(
        (row) => (
            <>
                <ExpandedDetails row={row.row.original} conversionrate={conversionrate} symbol={row.row.original.cur_symbol} language={language} handleClick={handleClick} totalinrcar={totalinrcar} totalourprice={totalourprice} diamondselected={diamondselected} buypopup={buypopup} BuyDiamond={BuyDiamond} HoldDiamond={HoldDiamond} symbolname={row.row.original.cur_symbol_name} setStockAPI={setStockAPI} StockAPI={StockAPI} location={location} pagenumber={pagenumber} expandrow={row} userinfo={userinfo}/>
            </>
        ),[StockAPI,pagenumber]
      )
      useEffect(() => {
        if (StockAPI){
            setTimeout(function() { 
                setLoader(false)
            }, 1000);
        } else{
            setLoader(true)
        }
        setInfiniteLoader(false)
    }, [StockAPI]);
    // console.log(diamonddetails,"diamonddetails")
    const columns = useMemo(()=> MockSearchResultColumn(conversionrate,user,symbol,GetRatio,language,history,userinfo,StockAPI),[StockAPI,loader,location.state] )
    // const data = useMemo(()=> StockAPI,[])
    // const RowExpanded = async(rowvalue) => {
    //     const getipadress = await axios.get("https://api.ipify.org")
    //     // console.log(getipadress.data,"getipadress")
    //     axios({
    //         method: "POST",
    //         url: `${NODE_API_URL}/AUS/expandAndDetailsLogAPI`,
    //         headers: {
    //             "Content-Type": "application/json",
    //              "Authorization": `Bearer ${Cookies.get("cust_auth_token")}` 
    //         },
    //         data: {
    //             userid:userinfo.id,
    //             Certi_no:rowvalue.original.certificate,
    //             C_Name:rowvalue.original.C_Name,
    //             type:"Expand",
    //             ip:getipadress.data
    //         },
    //     }).then(response => {
    //         // console.log(response.data,"responseExpand")
    //     }).
    //     catch(error => {
    //       if(error?.response?.data?.message === "UnAuthorized!"){
    //         var cookies = document.cookie.split(";");
    //       for (var i = 0; i < cookies.length; i++) {
    //         if(cookies[i] && !cookies[i].includes("view")){
    //           var equals = cookies[i].indexOf("=");
    //           var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
    //           document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    //         }
    //       }
          
    //       window.location.replace(`${BASE_URL}/logout`);
    //     }
    //     })
    // }
    const {
        getTableProps,
        getTableBodyProps,
        prepareRow,
        headerGroups,
        page,
        rows,
        toggleAllRowsSelected,
        // canPreviousPage,
        // canNextPage,
        // pageOptions,
        // gotoPage,
        // pageCount,
        visibleColumns, 
        // setPageSize,
        // setGlobalFilter,
        selectedFlatRows,
        allColumns,
        getToggleHideAllColumnsProps,
        state:{
                // globalFilter,
                // pageIndex,
                // pageSize,ghbhb
                selectedRowIds
            },
    } = useTable({
        columns,
        data:StockAPI,
        initialState:{ pageSize: 1000000000},
        autoResetExpanded:!infiniteLoader,
        disableSortRemove: true,
        autoResetSelectedRows: !infiniteLoader,
    },
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
        (hooks) =>{
            hooks.visibleColumns.push((columns)=>{
                return[
                    {
                        id:'selection',
                        Header:({ getToggleAllPageRowsSelectedProps }) =>(
                            <CheckBox title="Select" {...getToggleAllPageRowsSelectedProps()} />
                        ),
                        Cell: ({ row }) => (
                            <div>
                              <CheckBox title="Select" {...row.getToggleRowSelectedProps()} />
                            </div>
                          ),
                    },
                    // {
                    // Header: () => null,
                    // id: 'expander',
                    //     Cell: ({ row }) => (
                    //         <span
                    //             onClick={(e) => {
                    //                 e.stopPropagation(); // Prevent row click event propagation
                    //                 row.toggleRowExpanded();
                    //             }}
                    //         >
                    //             {row.isExpanded ? <FaChevronDown className="text-success "  title="Collapse"/> :  <FaChevronRight className="text-primary" title="Expand" onClick={() => RowExpanded(row)}/>}
                    //         </span>
                    //     ),
                    // },
                    ...columns,
                ]
            })
        }
    )

    useEffect(() => {
        // This will run every time the selected rows change
        setSelectedRows(rows.filter(row => row.isSelected).reduce((acc, row) => {
          acc[row.id] = row.isSelected;
          return acc;
        }, {}));
      }, [rows]);
    
      const deselectAll = () => {
        toggleAllRowsSelected(false);
      };

    useEffect(() => {
        let temptotalcarat = 0
        let temptotalinrcar = 0
        let temptotalourprice = 0
        selectedFlatRows.map(value => {
            const finddata = StockAPI.find(val => value.original.stockID.toString() === val.stockID.toString())
            if(finddata){
                temptotalcarat += finddata.carat
                temptotalinrcar += (selectedFlatRows.length>0?finddata.ourRate/selectedFlatRows.length:0)
                temptotalourprice += Math.round(finddata.ourPrice * 100)/100
            }
        })
        setTotalCarat(temptotalcarat)
        setTotalInrCar(selectedFlatRows.length>0?(temptotalourprice/temptotalcarat * conversionrate):0)
        setTotalOurPrice(temptotalourprice * conversionrate)
        let selecteddata = selectedFlatRows.map(value => {
            const finddata = StockAPI.find(val => value.original.stockID.toString() === val.stockID.toString())
            if(finddata){
                return {
                    "Certi_NO":finddata.certificate,
                    "diamond_type":finddata.diamondType,
                    "tracklisted_price_usd":finddata.ourPrice,
                    "tracklisted_price":finddata.ourPriceExchange,
                    "cur_symbol_name":"AUD",
                    "cur_rate":finddata.curr_aud
                }
            }
        })
        setDiamondDetail(selecteddata)
    },[selectedFlatRows])
    const HandlePagination = (pageno) => {
        setInfiniteLoader(true)
        const fancy = location.state.oldvalues.diamond_type === "N"?Cookies.get("fancy")?1:0:Cookies.get("fancybasic")?1:0
        window.Nat = location.state.oldvalues;
        let len = Object.keys(location.state.oldvalues).length;
        const temp = location.state.oldvalues;
        let data = "";
        for (let key in temp) {
            len--;
            let arr = temp[key];
            if (len) {
                if (Array.isArray(arr)) {
                    let str = "";
                    str += "'" + arr.join("','") + "'";
                    data += `{"FilterName": "${key}","FilterValue":"${str}" }, `;
                } else {
                    data += `{"FilterName": "${key}","FilterValue": "'${temp[key]}'"},`;
                }
            } else {
                if (Array.isArray(arr)) {
                    let str = "";
                    str += "'" + arr.join("','") + "'";
                    data += `{"FilterName": "${key}","FilterValue":"${str}" } `;
                } else {
                    data += `{"FilterName": "${key}","FilterValue": "'${temp[key]}'"}`;
                }
            }
        }
        const finalData = `{ "JsonData":  [${data}] , "CustomerId": "${userinfo.id}", "ConsumerId":${ id === "" ? "-1" : id }, "WLDiamondType": "${location.state.oldvalues.diamond_type}" ,"page":${pageno},"conversionrate":"${conversionrate}","curr":"${symbolname}","sortobj":${JSON.stringify(sortobj)},"fancy":"${fancy}","loginLogId":${Cookies.get("_LogId")}}`;
        if(location.state.oldvalues.diamond_type === "N"){
            localStorage.setItem("modifysearch",JSON.stringify({...location.state.oldvalues}))
        }
        else{
            localStorage.setItem("modifysearchlab",JSON.stringify({...location.state.oldvalues}))
        }
        axios({
            method: "POST",
            url: `${NODE_API_URL}/AUS/searchDiamond`,
            headers: {
                "Content-Type": "application/json",
                 "Authorization": `Bearer ${Cookies.get("cust_auth_token")}` 
            },
            data: finalData,
        })
        .then(function(res) {
            // console.log("PAGINATIONNNNN")
            let arraycheck = [
                ...StockAPI,
                ...res.data.data
            ]
            // console.log(arraycheck.length,"LENGTHGHHHHH")
            setStockAPI([
                ...StockAPI,
                ...res.data.data
            ])
            // history.push({
            //     pathname: "/search-result",
            //     state: {
            //         searchresult:res.data.data,
            //         oldvalues:location.state.oldvalues,
            //         page:pageno
            //     },
            //         // state: decompress(res.data.ProductDetails),
            //     });
        })
        .
        catch(error => {
          if(error?.response?.data?.message === "UnAuthorized!"){
            var cookies = document.cookie.split(";");
          for (var i = 0; i < cookies.length; i++) {
            if(cookies[i] && !cookies[i].includes("view")){
              var equals = cookies[i].indexOf("=");
              var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
              document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
            }
          }
          
          window.location.replace(`${BASE_URL}/logout`);
        }
        })
    }
    const SetSorting = (column) => {
        // console.log(column.id,"SetSorting")
        if(disablesorting){
            return
        }
        if(column.id === "selection"){
            return
        }
        if(!sortobj.id){
            setSortObj({id:column.id,sortorder:"asc"})
        }
        else if(sortobj.id !== column.id){
            setSortObj({id:column.id,sortorder:"asc"})
        }
        else if(sortobj.id === column.id && sortobj.sortorder === "desc"){
            setSortObj({id:column.id,sortorder:"asc"})
        }
        else if(sortobj.id === column.id && sortobj.sortorder === "asc"){
            setSortObj({id:column.id,sortorder:"desc"})
        }
    }
    useEffect(() => {
        if(location.state.sortobj){
            setSortObj(location.state.sortobj)
        }
    },[])
    useEffect(() => {
        if(sortobj && sortobj.id){
            setLoader(true)
            const fancy = location.state.oldvalues.diamond_type === "N"?Cookies.get("fancy")?1:0:Cookies.get("fancybasic")?1:0
            window.Nat = location.state.oldvalues;
            let len = Object.keys(location.state.oldvalues).length;
            const temp = location.state.oldvalues;
            let data = "";
            for (let key in temp) {
                len--;
                let arr = temp[key];
                if (len) {
                    if (Array.isArray(arr)) {
                        let str = "";
                        str += "'" + arr.join("','") + "'";
                        data += `{"FilterName": "${key}","FilterValue":"${str}" }, `;
                    } else {
                        data += `{"FilterName": "${key}","FilterValue": "'${temp[key]}'"},`;
                    }
                } else {
                    if (Array.isArray(arr)) {
                        let str = "";
                        str += "'" + arr.join("','") + "'";
                        data += `{"FilterName": "${key}","FilterValue":"${str}" } `;
                    } else {
                        data += `{"FilterName": "${key}","FilterValue": "'${temp[key]}'"}`;
                    }
                }
            }
            const finalData = `{ "JsonData":  [${data}] , "CustomerId": "${userinfo.id}", "ConsumerId":${ id === "" ? "-1" : id }, "WLDiamondType": "${location.state.oldvalues.diamond_type}" ,"page":1, "conversionrate":"${conversionrate}","curr":"${symbolname}","sortobj":${JSON.stringify(sortobj)},"fancy":"${fancy}","loginLogId":${Cookies.get("_LogId")}}`;
            if(location.state.oldvalues.diamond_type === "N"){
                localStorage.setItem("modifysearch",JSON.stringify({...location.state.oldvalues}))
            }
            else{
                localStorage.setItem("modifysearchlab",JSON.stringify({...location.state.oldvalues}))
            }
            axios({
                method: "POST",
                url: `${NODE_API_URL}/AUS/searchDiamond`,
                headers: {
                    "Content-Type": "application/json",
                     "Authorization": `Bearer ${Cookies.get("cust_auth_token")}` 
                },
                data: finalData,
            })
            .then(function(res) {
                // console.log("SORTTTT")
                setStockAPI(res.data.data)
                setPageNumber(1)
                setLoader(false)
                history.push({
                    pathname: `/search-result/${StockAPI[0].diamondType}`,
                    state: {
                        searchresult:res.data.data,
                        oldvalues:location.state.oldvalues,
                        page:1
                    },
                        // state: decompress(res.data.ProductDetails),
                    });
            })
            .
        catch(error => {
          if(error?.response?.data?.message === "UnAuthorized!"){
            var cookies = document.cookie.split(";");
          for (var i = 0; i < cookies.length; i++) {
            if(cookies[i] && !cookies[i].includes("view")){
              var equals = cookies[i].indexOf("=");
              var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
              document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
            }
          }
          
          window.location.replace(`${BASE_URL}/logout`);
        }
        })
        }
    },[sortobj])
    const AddToTracklist = () => {
        if(diamonddetails.length === 0){
            setSwalWarnOpen(true)
            setAlertMessage("Please Select at least One Record ...!!")
            return
        }
        setShow(true)
    }
    function numberWithCommas(x) {
        if (x) {
            return x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        } else {
            return "0.00";
        }
    }

    const DropdownWithPortal = ({ children, ...props }) => {
        return createPortal(
          <Dropdown.Menu {...props}>{children}</Dropdown.Menu>,
          document.body
        );
      };
      
      useEffect(() => {
        const handleResize = () => {

          if (scrollRef.current) {
            // Calculate height in pixels based on 77vh
            const heightInPixels = window.innerHeight * 0.77;
            if (heightInPixels === 535.15){

                scrollRef.current.style.height = `${heightInPixels}px`;
            }else if (heightInPixels > 535.15){
                scrollRef.current.style.height = `${heightInPixels+20}px`;
                
            }else{
                scrollRef.current.style.height = `${heightInPixels-20}px`;

            }
          }
        };
    
        window.addEventListener('resize', handleResize);
        handleResize(); // Set initial height
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);

    return (
        <>
            <TracklistStone SearchId={StockAPI[0].search_result_id} show={show} setShow={setShow} language={language} diamonddetails={diamonddetails} userinfo={userinfo} symbolname={symbolname} conversionrate={conversionrate}/>
            <SwalWarn show={swalWarnOpen} message={alertMessage} onClose={() => setSwalWarnOpen(false)} /> 
            <SwalError show={swalErrorOpen} message={alertMessage} onClose={() => setSwalErrorOpen(false)} /> 
            <CompareDiamond onHide={() => setComparePopup(false)} show={comparePopup} diamondselected={diamondselected} language={language} symbol={symbol} userinfo={userinfo}/>
            <ConfirmGoods show={buypopup} onHide={() => setBuyPopUp(false)} diamondselected={diamondselected} symbol={symbol} conversionrate={conversionrate} totalinrcar={buypopupcinr} totalourprice={buypopupctotal} diamondcount={diamondcount} language={language} userinfo={userinfo}/>  
            <HoldPopup show={holdpopup} onHide={() => setHoldPopUp(false)} diamondselected={diamondselected} symbol={symbol} conversionrate={conversionrate} totalinrcar={buypopupcinr} totalourprice={buypopupctotal} diamondcount={diamondcount} language={language}  userinfo={userinfo}/>  
            {loader ? <SearchTableSkeleton /> :
                <>
                 {StockAPI.length > 0 ?
                    <div className={`card searchResultCard card-custom card-sticky ${StockAPI.length > 0 && StockAPI[0].diamondType === "L" ? "labTab" : "natTab"}`}>
                        {selectedFlatRows.length>0?
                            <>
                                {isDropdown ? 
                                        <div className="card-header selecetedHeader custom-scrollbar text-nowrap flex-nowrap pl-10">
                                            <div className="d-flex justify-content-center align-items-center">
                                                <button type="button" className="btn btn-primary mr-2 px-8 text-nowrap"  onClick={() => BuyDiamond(selectedFlatRows) }> <BsLightningFill className='font-size-h5' />{language.SEARCHLIST_BUYDIAMONDBTN}</button>
                                                <button type="button" className="btn btn-hover-light-dark mr-1"  onClick={() => HoldDiamond(selectedFlatRows) }><SVG className="ol mr-2" src={toAbsoluteUrl("/media/svg/icons/Custom/hold.svg")} height="18px" width="18px" /> {language.SEARCHLIST_HOLDDIAMONDBTN}</button>
                                                <Dropdown alignRight>
                                                    <Dropdown.Toggle variant="clear" className="btn-icon btn-hover-light-dark">
                                                        {/* <BsSortDown /> {getSelectedOption()} <RiArrowDropDownLine /> */}
                                                        <FaEllipsisH />
                                                    </Dropdown.Toggle>

                                                    <DropdownWithPortal>
                                                        <PerfectScrollbar
                                                            options={perfectScrollbarOptions}
                                                            className="scroll"
                                                            style={{ maxHeight: "400px", position: "relative" }}
                                                        >
                                                                <React.Fragment >
                                                                    <Dropdown.Item 
                                                                        style={{ padding:" 0.35rem 1.25rem"}}
                                                                        onClick={() => AddToTracklist() }
                                                                    >
                                                                        <SVG className="ol mr-2" src={toAbsoluteUrl("/media/svg/icons/Custom/Track.svg")} height="18px" width="18px" /> {language.TRACKLIST_STONE_BTN}
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item 
                                                                        style={{ padding:" 0.35rem 1.25rem"}}
                                                                        onClick={() => CompareDiam(selectedFlatRows) }
                                                                    >
                                                                        <IoGitCompareOutline className='font-size-h5' />{language.DIAMOND_COMPARISION_BTN}
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item 
                                                                        style={{ padding:" 0.35rem 1.25rem"}}
                                                                        onClick={() => ExcelDownload(selectedFlatRows) }
                                                                    >
                                                                       <FaRegFileExcel className='font-size-h5 mr-2' />{language.DOWNLOAD_EXCEL}
                                                                    </Dropdown.Item>
                                                                </React.Fragment>
                                                        </PerfectScrollbar>
                                                    </DropdownWithPortal>
                                                </Dropdown>
                                            </div>
                                            <div className="card-toolbar flex-nowrap">
                                                <Sorting sortobj={sortobj} setSortObj={setSortObj}/>
                                                <div>
                                                    <span className="selected-diamond-box mx-2">
                                                        <span className='cursor-pointer' onClick={deselectAll}><IoMdClose className='text-dark'/></span> {selectedFlatRows.length} {language.DIAMOND_COMPARISION_SELDIAMONDS} | {language.SEARCHLIST_CARAT} : {numberWithCommas(parseFloat(totalcarat))} | Amount : {symbol}{numberWithCommas(parseFloat(totalourprice))}
                                                    </span>
                                                </div>
                                                <OverlayTrigger placement="top" overlay={ <Tooltip id="tooltip"> Customize Table</Tooltip> } >
                                                    <button type="button" className="btn btn-hover-light-dark btn-icon mr-1 font-size-h5" onClick={() => setCustomizeTable(true)}><TbTableOptions /></button>
                                                </OverlayTrigger>
                                                <OverlayTrigger placement="top" overlay={ <Tooltip id="tooltip"> Grid view </Tooltip> } >
                                                    <button type="button" className="btn btn-hover-light-dark btn-icon mr-1 font-size-h5"  onClick={(e) => ChangeView()}><IoGridOutline /></button>
                                                </OverlayTrigger>
                                                <span>{location.state.oldvalues?<button className="btn btn-clear btn-hover-light-dark mr-1"  onClick={toggleDrawer('right', true)}><SVG className="ol mr-2" src={toAbsoluteUrl("/media/svg/icons/Custom/Modify.svg")} height="18px" width="18px" />{language.SEARCHLIST_MODIFYDIAMONDBTN}</button>:""}</span>
                                            </div>
                                        </div>
                                    :
                                        <div className="card-header custom-scrollbar flex-nowrap text-nowrap selecetedHeader pl-10">
                                            <div className="d-flex justify-content-center align-items-center">
                                                <button type="button" className="btn btn-primary mr-2 px-8 text-nowrap"  onClick={() => BuyDiamond(selectedFlatRows) }> <BsLightningFill className='font-size-h5' />{language.SEARCHLIST_BUYDIAMONDBTN}</button>
                                                <button type="button" className="btn btn-hover-light-dark mr-1"  onClick={() => HoldDiamond(selectedFlatRows) }><SVG className="ol mr-2" src={toAbsoluteUrl("/media/svg/icons/Custom/hold.svg")} height="18px" width="18px" /> {language.SEARCHLIST_HOLDDIAMONDBTN}</button>
                                                <button type="button" className="btn btn-hover-light-dark mr-1"  onClick={() => AddToTracklist() }><SVG className="ol mr-2" src={toAbsoluteUrl("/media/svg/icons/Custom/Track.svg")} height="18px" width="18px" /> {language.TRACKLIST_STONE_BTN}</button>
                                                <button type="button" className="btn btn-hover-light-dark mr-1 font-size-h5"  onClick={() => CompareDiam(selectedFlatRows) }><IoGitCompareOutline />{language.DIAMOND_COMPARISION_BTN}</button>
                                                <button type="button" className="btn btn-hover-light-dark mr-1"  onClick={() => ExcelDownload(selectedFlatRows) }><FaRegFileExcel className='font-size-h5 mr-2' />{language.DOWNLOAD_EXCEL}</button>
                                            </div>
                                            <div className="card-toolbar flex-nowrap">
                                                <Sorting sortobj={sortobj} setSortObj={setSortObj}/>
                                                <div>
                                                    <span className="selected-diamond-box mx-2">
                                                        <span className='cursor-pointer' onClick={deselectAll}><IoMdClose className='text-dark'/></span> {selectedFlatRows.length} {language.DIAMOND_COMPARISION_SELDIAMONDS} | {language.SEARCHLIST_CARAT} : {numberWithCommas(parseFloat(totalcarat))} | Amount : {symbol}{numberWithCommas(parseFloat(totalourprice))}
                                                    </span>
                                                </div>
                                                <OverlayTrigger placement="top" overlay={ <Tooltip id="tooltip"> Customize Table</Tooltip> } >
                                                    <button type="button" className="btn btn-hover-light-dark btn-icon mr-1 font-size-h5" onClick={() => setCustomizeTable(true)}><TbTableOptions /></button>
                                                </OverlayTrigger>
                                                <OverlayTrigger placement="top" overlay={ <Tooltip id="tooltip"> Grid view </Tooltip> } >
                                                    <button type="button" className="btn btn-hover-light-dark btn-icon mr-1 font-size-h5"  onClick={(e) => ChangeView()}><IoGridOutline /></button>
                                                </OverlayTrigger>
                                                <span>{location.state.oldvalues?<button className="btn btn-clear btn-hover-light-dark mr-1"  onClick={toggleDrawer('right', true)}><SVG className="ol mr-2" src={toAbsoluteUrl("/media/svg/icons/Custom/Modify.svg")} height="18px" width="18px" />{language.SEARCHLIST_MODIFYDIAMONDBTN}</button>:""}</span>
                                            </div>
                                        </div>
                                }
                            </>
                        :
                            <div className="card-header p-0">
                                <div className="card-title align-items-center">
                                    {countloader?<div className="card-label text-dark">Loading Count...</div>:<div className="card-label text-dark">{stoneCount} {StockAPI.length > 0 && StockAPI[0].diamondType === "L" ? "Lab Grown" : "Natural"} {language.SEARCHLIST_DIAMONDSFOUND} </div>}
                                </div>
                                <div className="card-toolbar flex-md-nowrap">
                                    <Sorting sortobj={sortobj} setSortObj={setSortObj}/>
                                    <OverlayTrigger placement="top" overlay={ <Tooltip id="tooltip"> Customize Table</Tooltip> } >
                                        <button type="button" className="btn btn-hover-light-dark btn-icon mr-1 font-size-h5" onClick={() => setCustomizeTable(true)}><TbTableOptions /></button>
                                    </OverlayTrigger>
                                    <OverlayTrigger placement="top" overlay={ <Tooltip id="tooltip"> Grid view </Tooltip> } >
                                        <button type="button" className="btn btn-hover-light-dark btn-icon mr-1 font-size-h5"  onClick={(e) => ChangeView()}><IoGridOutline /></button>
                                    </OverlayTrigger>
                                    <span>{location.state.oldvalues?<button className="btn btn-clear btn-hover-light-dark mr-1"  onClick={toggleDrawer('right', true)}><SVG className="ol mr-2" src={toAbsoluteUrl("/media/svg/icons/Custom/Modify.svg")} height="18px" width="18px" />{language.SEARCHLIST_MODIFYDIAMONDBTN}</button>:""}</span>
                                </div>
                            </div>
                        }
                        <div className="card-body mt-3 p-0">
                            <div className="table-responsive custom-scrollbar w-100" ref={scrollRef}>
                                <table className="search-result table mb-0 table-hover custom_datatable" {...getTableProps()}>
                                    {disablesorting?<thead >
                                        {headerGroups.map(headerGroup =>(
                                            <tr className='sticky-top bg-white' {...headerGroup.getHeaderGroupProps()}>
                                                { headerGroup.headers.map((column)=>(
                                                    <th id={column.id} className="text-center align-middle" {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                        {column.render('Header')}
                                                        <span className="text-secondary">
                                                        {column.isSorted ? (column.isSortedDesc ? <FaSortDown /> : <FaSortUp />): ''}
                                                        </span>
                                                    </th>
                                                ))}
                                            </tr>
                                        ))}
                                    </thead >:<thead className='sticky-top bg-white'>
                                        {headerGroups.map(headerGroup =>(
                                            <tr {...headerGroup.getHeaderGroupProps()}>
                                                { headerGroup.headers.map((column)=>(
                                                    <th className="text-center align-middle" {...column.getHeaderProps(column.getSortByToggleProps())}  onClick={() => SetSorting(column)}>
                                                        {column.render('Header')}
                                                        <span className="text-secondary">
                                                            {column.id === sortobj.id ? (sortobj.sortorder === "desc" ? <FaSortDown /> : <FaSortUp />): ''}
                                                        </span>
                                                    </th>
                                                ))}
                                            </tr>
                                        ))}
                                    </thead>}
                                    <tbody {...getTableBodyProps()}>
                                        {page.map((row) => {
                                            prepareRow(row)

                                            const isSelected = selectedRowIds[row.id];
                                            return(
                                                <>
                                                
                                                    <tr
                                                        {...row.getRowProps()}
                                                        key={row.id}
                                                        className={`${isSelected ? 'bg-success-o-30' : ''} ${(row.original.diamond_type === "N" || row.original.diamond_type === "W") && (row.original.green && row.original.green.toLowerCase() !== "none") ? 'bg-danger-o-20' : ''}`}
                                                        onClick={() => {row.toggleRowExpanded();}}
                                                        style={{ cursor: 'pointer' }} // Change cursor to indicate clickable row
                                                    >
                                                        {row.cells.map((cell,i) => {
                                                            return <td  className="text-center align-middle" {...cell.getCellProps({ className: cell.column.className})} key={i}>{cell.render('Cell')}</td>
                                                        })}
                                                    </tr>
                                                    {row.isExpanded ? (
                                                        <tr>
                                                            <td className="p-0" colSpan={visibleColumns.length}>
                                                                {renderRowSubComponent({ row })} 
                                                            </td>
                                                        </tr>
                                                    ) : null}
                                                </>
                                            )
                                        })}
                                    </tbody>
                                </table>
                                {infiniteLoader ? <InfiniteTableSkeleton /> :null}
                                {StockAPI.length === parseFloat(stoneCount)?<div className="d-flex align-items-center justify-content-center">No more stones found</div>:""}
                            </div> 
                        </div>
                        

                        {/* <div className="card-footer mt-5 p-0 border-0">
                            <div className="d-flex align-items-center justify-content-between">
                                <span className="table_page_length">Page{' '}{pagenumber } of {Math.ceil(parseFloat(stoneCount)/100)} {' '}</span>
                                <div>
                                    <button className="btn btn-sm btn-light mr-2" onClick={()=>HandlePagination(1) } disabled={pagenumber === 1}><FaAngleDoubleLeft /></button>
                                    <button className="btn btn-sm btn-light mr-2" onClick={() => HandlePagination(pagenumber - 1)} disabled={pagenumber === 1}><FaAngleLeft /></button>
                                    <span className="font-weight-bold text-muted">{'  '}
                                        <input
                                            className="form-control d-inline-block form-control-sm"
                                            type="number"
                                            value={pagenumber}
                                            // defaultValue={pageIndex + 1}
                                            onChange={ e => { 
                                                const pageNumber=e.target.value ? Number(e.target.value) - 1 : 0
                                                gotoPage(pageNumber)
                                            }}
                                            style={{maxWidth:'70px'}}
                                        />
                                        {'  '}of{'  '} {Math.ceil(parseFloat(stoneCount)/100)}{'  '}
                                    </span>
                                    <button className="btn btn-sm btn-light mr-2" onClick={() => HandlePagination(pagenumber + 1)} disabled={StockAPI.length < 100}><FaAngleRight /></button>
                                    <button className="btn btn-sm btn-light" onClick={()=>HandlePagination(Math.ceil(parseFloat(stoneCount)/100))} disabled={StockAPI.length < 100}><FaAngleDoubleRight /></button>
                                </div>
                                <span className="d-flex align-items-center">
                                <span className="text-nowrap page_size">Rows Per page:{' '}</span>
                                    <select className="form-select ml-2 custom-select py-1" value={pageSize} onChange={e => setPageSize(Number(e.target.value))}>
                                        {
                                            [100].map(pageSize => (
                                                <option key={pageSize} value={pageSize}>{pageSize}</option>
                                            ))
                                        }
                                    </select>
                                </span>
                            </div>
                        </div> */}
                    </div> :
                    <div className="card custom-card">
                        <div className="card-body d-flex align-items-center  justify-content-center" style={{height:'70vh'}}>
                            <div className="text-center">
                                <img src={toAbsoluteUrl("/media/General/searchresult-no.png")} className="img-fluid" alt="searchresult-no"/>
                                <div className="mt-8 font-size-h4 text-capitalize text-primary"><strong>{modifymsg}</strong></div>
                                <div class="mt-3 text-muted">Please Modify Your Search</div>
                                {/* <Link className="btn btn-primary px-8 mt-5" to={{ pathname:'/searchdiamond/white', option: "white" }}>Search Diamonds</Link> */}
                                <SwipeableDrawer
                                    anchor="right"
                                    open={swipeableDrawer.right}
                                    onClose={toggleDrawer('right', false)}
                                    onOpen={toggleDrawer('right', true)}
                                >
                                    {/* {sideList('right')} */}
                                    <ModifiedSearchOffcanvas language={language} diamond_type={dimondType} setStockAPI={setStockAPI} oldvalues={location.state.oldvalues} setSwipeableDrawer={setSwipeableDrawer} setLoader={setLoader} setModifyMessage={setModifyMessage} conversionrate={conversionrate} symbolname={symbolname} setPageNumber={setPageNumber} setSortObj={setSortObj}  setUpdateCount={setUpdateCount} updatecount={updatecount} userinfo={userinfo}/>
                                </SwipeableDrawer>
                                {location.state.oldvalues?<button className="btn btn-primary mt-5 px-8" onClick={toggleDrawer('right', true)}>{language.SEARCHLIST_MODIFYDIAMONDBTN}</button>:""}
                            </div>
                        </div>
                    </div>
                 }
                </>
            } 
             <Modal
                size="sm"
                show={CustomizeTable}
                onHide={() => setCustomizeTable(false)}
                aria-labelledby="Customize-table"
            >
                <Modal.Header className="px-5 py-3" closeButton>
                    <Modal.Title id="Customize-table">
                        Customize Columns
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-2 searchResult">
                    <PerfectScrollbar
                        options={perfectScrollbarOptions}
                        className="scroll"
                        style={{ maxHeight: "400px", position: "relative" }}
                    >
                    {/* <div  className="" > */}
                        <div>
                            <label className="checkbox my-2 border p-2">
                                <input type='checkbox' {...getToggleHideAllColumnsProps()}  />
                                <span className="mr-2"></span>
                                Show All
                            </label> 
                        </div>
                        {
                            allColumns.map(column =>(
                                <div key={column.id} className={`${column.id}`}>
                                    <label className="checkbox my-2 border p-2">
                                        <input type='checkbox' {...column.getToggleHiddenProps()}  />
                                        <span className="mr-2"></span>
                                        {column.Header}
                                    </label>
                                </div>
                            ))
                        }
                    {/* </div> */}
                    </PerfectScrollbar>
                </Modal.Body>
                <Modal.Footer className="px-5 py-3">
                    {/* <button type="button" className="btn btn-light mr-2 text-nowrap" onClick={handleClose}>Close</button> */}
                    <button type="button" className="btn btn-secondary mr-2 text-nowrap" onClick={handleClose}>Save Changes</button>
                </Modal.Footer>
            </Modal>
            <SwipeableDrawer
                anchor="right"
                open={swipeableDrawer.right}
                onClose={toggleDrawer('right', false)}
                onOpen={toggleDrawer('right', true)}
            >
                {/* {sideList('right')} */}
                <ModifiedSearchOffcanvas language={language} diamond_type={dimondType} setStockAPI={setStockAPI} oldvalues={location.state.oldvalues} setSwipeableDrawer={setSwipeableDrawer} setLoader={setLoader} setModifyMessage={setModifyMessage} conversionrate={conversionrate} symbolname={symbolname} setPageNumber={setPageNumber} setSortObj={setSortObj} setUpdateCount={setUpdateCount} updatecount={updatecount} userinfo={userinfo}/>
            </SwipeableDrawer>
        </>
    )
}